import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <AiOutlineLeft
        {...props}
        className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
    >
    </AiOutlineLeft>
);
export const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <AiOutlineRight
        {...props}
        className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
    </AiOutlineRight>
);

const ImgSlickSettings = {
    slidesToShow: 4,
    infinite: true,
    autoplay: true,
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
        { breakpoint: 1366, settings: { slidesToShow: 4 } },
        { breakpoint: 992, settings: { slidesToShow: 4 } },
        { breakpoint: 768, settings: { slidesToShow: 4 } },
        { breakpoint: 576, settings: { slidesToShow: 1 } }
    ]
}

const BritacelImgsSlickSettings = {
    slidesToShow: 6,
    infinite: true,
    autoplay: true,
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
        { breakpoint: 1366, settings: { slidesToShow: 6 } },
        { breakpoint: 992, settings: { slidesToShow: 6 } },
        { breakpoint: 768, settings: { slidesToShow: 3 } },
        { breakpoint: 576, settings: { slidesToShow: 1 } }
    ]
}

const FeedbackSlickSettings = {
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
}

const SingleProductImgsSlickSettings = {
    slidesToShow: 1,
    vertical: true,
    autoplay: false,
    dots: false,
    arrows: false,
    asNavFor: ".slider-nav",
    className: "slider-for mx-0 pl-xl-5",
    infinite: false,
    responsive: [{ breakpoint: 1200, settings: { vertical: false } }]
}

export { ImgSlickSettings, BritacelImgsSlickSettings, FeedbackSlickSettings, SingleProductImgsSlickSettings };