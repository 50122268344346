import { AiOutlineArrowUp } from "react-icons/ai";

const BackToTopButton = () => {

    const goToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <a onClick={goToTop} id="backToTop" className="gtf-back-to-top text-decoration-none bg-white hover-white bg-hover-primary shadow p-0 w-48px h-48px rounded-circle fs-24 d-flex align-items-center justify-content-center" title="Back To Top">
            <AiOutlineArrowUp />
        </a>
    )
}

export default BackToTopButton;