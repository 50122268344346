import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector, useDispatch } from "react-redux";
import {
  setCart,
  removeProductFromCart,
} from "../components/Product-Detail/add-to-cart-reducer";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const Cart = ({ show, handleClose }) => {
  const cartvalue = useSelector((state) => state.addToCart.value);
  const profileData = useSelector((state) => state.loggedIn.value.profileData);
  const loggedIn = useSelector((state) => state.loggedIn.value.loggedIn);
  const dispatch = useDispatch();

  const checkSamplesInCart = () =>
    cartvalue?.find((i) => i?.categories?.title === "Samples");

  const getTotalPrice = () => {
    let price = 0;
    for (let c of cartvalue) {
      price += c.price * c.qty;
    }
    return Math.ceil(price);
  };

  const removeFromCart = (id, selectPack) => {
    dispatch(removeProductFromCart({id, selectPack}));
    // const arr = cartvalue.filter(
    //   (i) => i.id !== id && id.selectPack !== selectPack
    // );
    const newPackArrayIndex = cartvalue.findIndex(
      (i) => i.id == id && i.selectPack === selectPack
    );
    console.log({ newPackArrayIndex });
    const arr = cartvalue.filter((i, index) => index != newPackArrayIndex);
    localStorage.setItem(
      `cart-value${profileData?.id || ""}`,
      JSON.stringify(arr)
    );
  };

  const setProductQty = (id, qty, selectPack) => {
    let _cart = cartvalue.map((i) => ({ ...i }));
    let product = _cart.find((i) => i.id === id && i.selectPack == selectPack);
    product.qty = qty;
    dispatch(setCart(_cart));
    localStorage.setItem(
      `cart-value${profileData?.id || ""}`,
      JSON.stringify(_cart)
    );
  };

  const cart =
    !cartvalue || cartvalue.length === 0 ? (
      <p className="text-center text-danger">Cart Empty ?</p>
    ) : (
      cartvalue.map((i) => (
        <div className="mb-4 d-flex" key={i.id}>
          <a
            className="d-flex align-items-center mr-2 text-muted cursor-pointer"
            onClick={() => removeFromCart(i.id, i.selectPack)}
          >
            <i className="fa fa-times"></i>
          </a>
          <div className="media w-100">
            <div className="w-60px mr-3">
              <img src={i.images.path} alt={i.name} />
            </div>
            <div className="media-body d-flex">
              <div className="cart-price pr-6">
                <p className="fs-14 font-weight-bold text-secondary mb-1 color-black">
                  ₹ {i.price * i.qty}
                </p>
                <p className="text-secondary color-black">
                  {i.name?.split(" | ")?.[0]}  {i?.selectPack ? `${(i?.[`${i?.selectPack}_name`])}` :""}
                </p>
                
              </div>
              {!checkSamplesInCart() && (
                <div className="position-relative ml-auto">
                  <div className="input-group position-relative w-100 color-black">
                    <button
                      type="button"
                      className="position-absolute color-black pos-fixed-left-center pl-2 z-index-2"
                      onClick={() =>
                        setProductQty(i.id, i.qty - 1, i.selectPack)
                      }
                      disabled={i.qty === 1}
                    >
                      <FaMinus />
                    </button>
                    <input
                      name="quantity"
                      type="number"
                      id="input-quantity-4"
                      className="form-control w-90 h-40 px-6 text-center input-quality border-0"
                      min={1}
                      max={999}
                      value={i.qty}
                      required
                      readOnly
                    />
                    <button
                      type="button"
                      className="position-absolute color-black pos-fixed-right-center pr-2 z-index-2"
                      onClick={() =>
                        setProductQty(i.id, i.qty + 1, i.selectPack)
                      }
                      disabled={i.qty === 999}
                    >
                      <FaPlus />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))
    );

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      className="cart-canvas"
    >
      <Offcanvas.Header closeButton>
        <div className="card-header bg-transparent p-0 mx-2 mt-4 color-black fw-600">
          <h3 className="fs-24 mb-4 fw-600">Shopping bag</h3>
          <p className="fs-15 font-weight-500 text-body mb-0">
            <span className="d-inline-block mr-2 fs-15 text-secondary">
              <i className="far fa-check-circle color-black"></i>
            </span>{" "}
            Your cart is saved
          </p>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="cart-body px-2 pt-7 overflow-y-auto">{cart}</div>
      </Offcanvas.Body>
      {cartvalue && cartvalue.length > 0 && (
        <div className="card-footer mt-auto border-0 bg-transparent px-6 pb-0 pt-5">
          <div className="d-flex align-items-center mb-2">
            <span className="text-secondary fs-15 color-black">
              Total price:
            </span>
            <span
              className="d-block ml-auto fs-24 font-weight-bold text-secondary color-black"
              id="textprice"
            >
              ₹ {getTotalPrice()}
            </span>
          </div>
          <Link
            to={loggedIn ? "/checkout" : "/pre-checkout"}
            className="btn btn-secondary btn-block mb-3 bg-hover-primary border-hover-primary"
            onClick={handleClose}
          >
            Check Out
          </Link>
          <button
            type="button"
            className="btn btn-continue btn-block bg-hover-primary border-hover-primary"
            onClick={handleClose}
          >
            Continue Shopping
          </button>
        </div>
      )}
    </Offcanvas>
  );
};
export default Cart;
