const OrderStatus = ({currStep}) => {
    const progressSteps = [
        { name: "Login", active: false },
        { name: "Shipping", active: false },
        { name: "Payment", active: false },
        { name: "Confirmation", active: false },
    ];
    return (
        <div className="menu-checkout-progress-wrapper clearfix">
            <ol className="menu-checkout-progress menu-horz-inline">
                {
                    progressSteps.map((s, i) => (
                        <li key={s.name} className={`progress-step step-${i} ${s.name === currStep ? "active" : "inactive"}`}>
                            {s.name}
                        </li>
                    ))
                }
            </ol>
        </div>
    )
}

export default OrderStatus;