import { useEffect, useState } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../axios-interceptors";
import "./order-history.css";

const OrderHistory = () => {
    const [orders, setOrders] = useState();
    const navigate = useNavigate();
    const loggedIn = useSelector((state) => state.loggedIn.value.loggedIn);

    useEffect(() => {
        if (loggedIn) {
            axios.get("orders").then(res => {
                setOrders(res);
            }).catch(err => {
                console.log(err);
                setOrders([]);
            })
        }
    }, [loggedIn]);

    return (
        <section className="pb-lg-13 pb-11 pt-7 order-sec-padding" style={{ marginTop: "100px" }}>
            {orders?.length > 0 && orders?.map(o => (
                <Card className="order-card" key={o.id} onClick={() => navigate(`/order-details/${o.id}/${o.oid}`)}>
                    <Card.Body>
                        <Row>
                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                <Row>
                                    <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                        <div className="img-section">
                                            <img className="order-img" src={o.orderItems[0]?.product?.images?.path} alt={o.orderItems[0]?.product?.name?.split(" | ")?.[0]} />
                                        </div>
                                    </Col>
                                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                        <span title={o.orderItems[0]?.product?.name}>
                                            <span className=" color-black">{o.orderItems[0]?.product?.name?.split(" | ")?.[0]}</span>
                                            <p className="mb-0">{o.orderItems[0]?.product?.name?.split(" | ")?.[1]}</p>
                                            {o.orderItems?.length > 1 &&
                                                <Badge pill bg="dark" className="ml-2">
                                                    +{o.orderItems?.length - 1}
                                                </Badge>
                                            }
                                        </span>

                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                <label className="radio-inline add-redio width-100">
                                    <span className="color-black radio-btn-pos mr-3">{o.addresses?.first_name}</span>
                                    <p className="mb-0">{`${o.addresses?.addr_line1}, ${o.addresses?.city}, ${o.addresses?.state}, ${o.addresses?.pincode}`}</p>
                                    <span className="color-black">Phone number </span>&nbsp;{o.addresses?.mobile}
                                </label>
                            </Col>
                            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="color-black">
                                ₹{o.total}
                            </Col>
                            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="color-black fw-600">
                                {o.updated_at?.split(" ")?.[0]}
                            </Col>
                            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="color-black text-uppercase">
                                {o.status}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            ))
            }
            {orders?.length === 0 && <div className="no-orders">
                <div className="no-orders-cover">
                    <BsSearch />
                    <span className="ml-3">No orders placed yet!</span>
                </div>
            </div>
            }
        </section>
    )
}

export default OrderHistory;