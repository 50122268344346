import { Col, Row } from 'react-bootstrap';
import { FaChevronCircleLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Login from "../components/login-form";
import { setResetPwd } from '../components/login-reducer';

const Signin = () => {
    const isResetPwd = useSelector(state => state.loggedIn.value.resetPwd);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <section className="pb-lg-13 pb-11 pt-7" style={{ marginTop: "100px" }}>
            <div className="container">
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="signin-block">
                        <h1 className="section-header color-black _mb-6">
                            {isResetPwd && <span className='go-back-signin' onClick={() => dispatch(setResetPwd(false))} ><FaChevronCircleLeft /></span>}
                            Existing customers
                        </h1>
                        <Login />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="signup-block">
                        <h1 className="section-header color-black _mb-6">Create an account</h1>
                        <div className="content-asset">
                            <p>Register with the Britacel Online Store and enjoy the benefits of having an account:</p>
                            <ul>
                                <li>Latest News &amp; Exclusive Offers</li>
                                <li>Order History &amp; Address Book</li>
                            </ul>
                        </div>
                        <button type='button' className='btn btn-signup btn-block bg-hover-primary border-hover-primary' onClick={() => navigate("/signup")}>Sign Up</button>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Signin;