import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';

const AboutUs = () => {
    const [intsersectObj, setIntersectObj] = useState({
        firstBlock: false, secondBlock: false, thirdBlock: false, fourthBlock: false,
        fifthBlock: false, sixthBlock: false, seventhBlock: false
    });
    const callbackFunc = (entries, observer) => {
        entries.forEach(entry => {
            setIntersectObj(prev => ({ ...prev, [entry.target.id]: entry.isIntersecting }));
            if (entry.isIntersecting) {
                document.getElementById(`${entry.target.id}Div`)?.classList?.add(`animation-${entry.target.id}`);
            }
        });
    }

    useEffect(() => {
        if (intsersectObj.firstBlock && !intsersectObj.secondBlock && !intsersectObj.thirdBlock && !intsersectObj.fourthBlock) {
            const blocks = ['secondBlock', 'thirdBlock', 'fourthBlock'];
            for (let b of blocks) {
                document.getElementById(`${b}Div`)?.classList?.remove(`animation-${b}`);
            }
        }

        if (!intsersectObj.fifthBlock && !intsersectObj.sixthBlock && !intsersectObj.seventhBlock) {
            const blocks = ['fifthBlock', 'sixthBlock', 'seventhBlock'];
            for (let b of blocks) {
                document.getElementById(`${b}Div`)?.classList?.remove(`animation-${b}`);
            }
        }
    }, [intsersectObj])

    let options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3
    };

    let observer = new IntersectionObserver(callbackFunc, options);

    useEffect(() => {
        observer.observe(document.getElementById('firstBlock'));
        observer.observe(document.getElementById('secondBlock'));
        observer.observe(document.getElementById('thirdBlock'));
        observer.observe(document.getElementById('fourthBlock'));
        observer.observe(document.getElementById('fifthBlock'));
        observer.observe(document.getElementById('sixthBlock'));
        observer.observe(document.getElementById('seventhBlock'));
    }, []);

    return (
        <div>
            <section className="pt-0 about-us-section pos-relative">
                <Row className='pos-relative'>
                    <Col className='pl-0 pr-0'>
                        <div className="hover-shine">
                            <img src="images/about-us1.jpg" alt="about us" />
                            <div className='about-us-title'>
                                <p className='title-first'>Into The</p>
                                <p className='vw-15'>House of britacel</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='first-block-aboutus' id="firstBlockDiv">
                    <p className='aboutus-header' id="firstBlock">Our chemistry</p>
                    <p className='aboutus-desc'>Expertly crafted formulations straight from the age-old vaults of our Swiss Laboratory. Experience the luxury of our exclusive home care brand, embodying Britacel’s strong Indian expertise and the finest Swiss technology, all in one bottle!</p>
                </div>

                <div className='second-block-aboutus' id='secondBlockDiv'>
                    <p className='aboutus-header' id="secondBlock">Integrating Science and Finance</p>
                    <p className='aboutus-desc'>We bring innovation to every drop of our solutions, crafting chemistries that require lower dosages to reach higher efficiency, leading to significant cost savings.</p>
                </div>

                <div className='third-block-aboutus' id="thirdBlockDiv">
                    <p className='aboutus-header' id="thirdBlock">Quality control & Assurance</p>
                    <p className='aboutus-desc'>For us, quality means delivering the best ingredients, formulas, and performance in every product. From responsible raw material sourcing to flawless packaging to building strong distributor channels, every aspect of our production process until our products reaches your doorstep, is focused on excellence.</p>
                </div>

                <div className='fourth-block-aboutus' id="fourthBlockDiv">
                    <p className='aboutus-header' id="fourthBlock">Fine fragrances</p>
                    <p className='aboutus-desc'>Specially blended fragrances made by experienced perfumers from France, utilizing premium and recycled ingredients. Our signature fragrances are distinctive and decadent to personify its bold and contemporary perspective.</p>
                </div>
            </section>

            <section className="pt-md-10 mobile-p-0 mob-mt">
                {/* <div className="_container container-xl p-0"> */}
                <Row>
                    <Col xs={6} sm={4} md={4} lg={4} xl={4} xxl={4} className="pr-0 pl-0">
                        <div className="hover-shine hover-zoom-in fadeInUp animated">
                            <img className='abt-img-height' src="images/about-us2.jpg" alt="about us" width={'100%'} />
                        </div>
                    </Col>
                    <Col sm={4} md={4} lg={4} xl={4} xxl={4} className="pr-0 pl-0 mob-d-none">
                        <div className="hover-shine hover-zoom-in fadeInUp animated">
                            <img className='abt-img-height' src="images/about-us3.jpg" alt="about us" width={'100%'} />
                        </div>
                    </Col>
                    <Col xs={6} sm={4} md={4} lg={4} xl={4} xxl={4} className="pr-0 pl-0">
                        <div className="hover-shine hover-zoom-in fadeInUp animated">
                            <img className='abt-img-height' src="images/about-us4.jpg" alt="about us" width={'100%'} />
                        </div>
                    </Col>
                </Row>
                {/* </div> */}
            </section>

            <section className="pt-md-10 mobile-p-0 mob-mb pos-relative" data-animated-id="3">
                <Row className='pos-relative'>
                    <Col className='pl-0 pr-0'>
                        <div className="hover-shine fadeInUp animated">
                            <img src="images/about-us5.jpg" alt="about us" />
                        </div>
                    </Col>
                </Row>
                <div className='fifth-block-aboutus' id="fifthBlockDiv">
                    <p className='aboutus-header' id="fifthBlock">Sustainability</p>
                    <p className='aboutus-desc'>Our manufacturing plant is committed to energy efficiency and sustainability by using solar power, compressed natural gas, and a zero-liquid discharge effluent treatment plant. Additionally, we have also implemented energy-saving equipments and reduced energy usage in our daily operations.</p>
                </div>

                <div className='sixth-block-aboutus' id="sixthBlockDiv">
                    <p className='aboutus-header' id="sixthBlock">Data driven performance</p>
                    <p className='aboutus-desc'>We have extensively studied the fields of health and home hygiene to bring you products that are backed by data and expertly crafted to deliver superior performance.</p>
                </div>

                <div className='seventh-block-aboutus' id="seventhBlockDiv">
                    <p className='aboutus-header' id="seventhBlock">Business model</p>
                    <p className='aboutus-desc'>Our business model caters to both individuals and businesses, providing personalized solutions to meet their unique requirements as a B2B and a B2C brand.</p>
                </div>
            </section>
        </div>
    )
}

export default AboutUs;