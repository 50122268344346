import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../axios-interceptors";
import "./order-details.css";
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { useSelector } from "react-redux";
import OrderStatus from "../order-status";

const OrderDetails = () => {
    const { id, oid } = useParams();
    const [invoiceUrl, setInvoiceUrl] = useState("");
    const [order, setOrder] = useState(null);
    const navigate = useNavigate();
    const loggedIn = useSelector((state) => state.loggedIn.value.loggedIn);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    const getSubTotalAmount = () => {
        let amount = 0;
        if (order && order.orderItems) {
            for (let i of order.orderItems) {
                amount += i.subtotal;
            }
        }
        return amount;
    }

    const getCouponDiscount = () => {
        let amount = 0;
        amount = getSubTotalAmount() - order?.total;
        if (amount < 0) amount = 0;
        return amount;
    }

    useEffect(() => {
        axios.get(`orders/${oid}`).then(res => {
            if (res.status === 'success') {
                setOrder(res.data);
            }
        }).catch(err => {
            console.log(err);
        });
        axios.post("download", { orderid: id }).then(res => {
            if (res.status === "success") {
                setInvoiceUrl(res.url);
            }
        }).catch(err => {
            console.log(err);
        });
    }, [id, oid]);

    return (
        <div className="order-invoice">
            {query.get('from') && <OrderStatus currStep={"Confirmation"} />}
            <section className="order-details-sec mt-4">
                {loggedIn && <span className="go-backto-orders" onClick={() => navigate("/order-history")}><BsFillArrowLeftCircleFill /></span>}
                <h2 className="mb-2">Thank you for shopping with us!</h2>
                <h6 className="mt-3 address-label">Shipping Address</h6>
                {order && <p className="fs-14">{`${order?.addresses?.addr_line1}, ${order?.addresses?.city}, ${order?.addresses?.state}, ${order?.addresses?.pincode}`}</p>}
            </section>
            <section className="mt-5">
                <div className="user-details">
                    <p>Full Name: <span className="color-black fw-600">{order?.addresses?.first_name}</span></p>
                    <p>Order Number: <span className="color-black fw-600">{order?.oid}</span></p>
                </div>
                <div className="user-details">
                    <p>Email: <span className="color-black fw-600">{order?.addresses?.email}</span></p>
                    <p>Invoice Date: <span className="color-black fw-600">{order?.updated_at}</span></p>
                </div>
                <div className="user-details">
                    <p>Contact No: <span className="color-black fw-600">{order?.addresses?.mobile}</span></p>
                </div>
            </section>
            <section className="mt-5">
                <table className="w-100 order-details-table">
                    <thead>
                        <tr>
                            <th width="50%">Item</th>
                            <th width="25%">Quantity</th>
                            <th width="25%">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            order?.orderItems?.map(i => (
                                <tr key={i.id} className="data-row">
                                    <td width="50%">
                                        <img className="mr-3" src={i?.product?.images?.path} width={"50px"} alt={i.product?.name?.split(" | ")?.[0]} />
                                        {i.product?.name?.split(" | ")?.[0]} 
                                        {i?.selectPack ? `${(i?.product?.[`${i?.selectPack}_name`])}` : ""}
                                    </td>
                                    <td width="25%">{i.quantity}</td>
                                    <td width="25%">₹{i.subtotal}</td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td width="50%" className="amount-label">Sub Total Amount:</td>
                            <td width="25%"></td>
                            <td width="25%">₹{getSubTotalAmount() || 0}</td>
                        </tr>
                        {getCouponDiscount() > 0 && <tr>
                            <td width="50%" className="amount-label">Coupon Discount</td>
                            <td width="25%"></td>
                            <td width="25%">-₹{getCouponDiscount() || 0}</td>
                        </tr>}
                        <tr className="data-row">
                            <td width="50%" className="amount-label">Shipping Charges:</td>
                            <td width="25%"></td>
                            <td width="25%">₹{order?.shipping_price || 0}</td>
                        </tr>
                        <tr className="data-row">
                            <td></td>
                            <td className="total-amt text-align-end">Total</td>
                            <td className="total-amt">₹{order?.total || 0}</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section className="mt-6 mb-6" style={{ textAlign: "center" }}>
                <h6>Thank you for your support. Your Britacel goodies will reach your doorstep within a
                    week’s time.</h6>
                <div className="btn-section">
                    <button type="button" className="btn btn-secondary btn-block bg-hover-primary border-hover-primary mt-5 mb-5 mr-3"
                        onClick={() => navigate("/shop")}>View More</button>
                    {invoiceUrl && <button type="button" className="btn btn-signup btn-block bg-hover-primary border-hover-primary mt-5 mb-5"
                        onClick={() => window.open(invoiceUrl, '_blank')}>Download Invoice</button>}
                </div>
            </section>
        </div>
    )
}

export default OrderDetails;