const ShuffleArr = (arr) => {
    let shuffledArr = arr
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    return shuffledArr;
}

const SortAndMap = (arr = []) => {
    const copy = arr.slice();
    const sorter = (a, b) => {
        return a['product_index'] - b['product_index'];
    };
    copy.sort(sorter);
    const res = copy.map(i => ({ ...i }));
    return res;
};

export { ShuffleArr, SortAndMap };