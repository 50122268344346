import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const getAccessToken = () => localStorage.getItem("access-token");

axiosInstance.interceptors.request.use(
    config => {
        const token = getAccessToken();
        const nonLoggedInToken = localStorage.getItem("non-loggedIn-token");
        config.headers['Content-Type'] = 'application/json';
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        } else if (nonLoggedInToken) {
            config.headers['Authorization'] = `Bearer ${nonLoggedInToken}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        // const originalRequest = error.config;

        // if (error.response.status === 401 && !originalRequest._retry) {
        //     originalRequest._retry = true
        //     const refreshToken = localStorage.getItem("refresh-token") ? JSON.parse(localStorage.getItem("refresh-token")) : "";
        //     return axios
        //         .post('/auth/refresh/token', {
        //             refresh_token: refreshToken
        //         })
        //         .then(res => {
        //             if (res.status === 201) {
        //                 localStorage.setItem("access-token", res.data);
        //                 axios.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken();
        //                 return axios(originalRequest)
        //             }
        //         })
        // }
        if (error.response && error.response.data) {
            throw error.response.data;
        }
        return Promise.reject(error);

    }
);

export default axiosInstance;