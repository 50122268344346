import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const DiscountPopup = ({ show, handleClose, discountObj }) => {
    const navigate = useNavigate();
    return (
        <Modal className='sign-in' show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Body className="p-0">
                <button className="btn p-0 close-btn" type="button" onClick={handleClose}><AiOutlineClose /></button>
                <img src={discountObj?.images?.path} alt="discount" onClick={() => navigate("/shop")} />
            </Modal.Body>
        </Modal>
    )
}

export default DiscountPopup;