import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../axios-interceptors";
import OrderStatus from "./order-status";

const PreCheckout = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const preCheckout = (e) => {
        e.preventDefault();
        axios.post(`auth/signup`, { email })
            .then((res) => {
                if (res && res.token) {
                    localStorage.setItem("non-loggedIn-token", res.token);
                    localStorage.setItem("non-loggedIn-email", email);
                    navigate("/checkout");
                } else {
                    toast.error("Login Error!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err ? err?.errors[0]?.message : "", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            });
    }

    // const imgUrl = localStorage.getItem(`cart-value`) ? JSON.parse(localStorage.getItem(`cart-value`))[0]?.images?.path : "";

    useEffect(() => {
        if (!localStorage.getItem(`cart-value`)) {
            navigate("/home");
        }
    }, []);

    return (
        <section className="pb-lg-13 pb-11 pt-7" style={{ marginTop: "70px" }}>
            <div className="site-wrapper">
                <OrderStatus currStep={"Login"} />
                <div className="login-detail-wrapper">
                    <Row>
                        <Col lg={4}>
                            <form onSubmit={preCheckout}>
                                <h2 className="section-header">Your details</h2>
                                <div className="mb-5">
                                    {/* <label>Email</label> */}
                                    <input name="email" type="email" className="form-control border-0 mb-3 valid" placeholder="Enter email"
                                        value={email} onChange={(evt) => setEmail(evt.target.value)} required />
                                    <p className="email-note">We will use this address to contact you about your order.</p>
                                </div>
                                <button type="submit" className="btn btn-secondary btn-block mb-3 bg-hover-primary border-hover-primary text-uppercase">Proceed to checkout</button>
                            </form>
                        </Col>
                        <Col lg={8}>
                            <img src="images/checkout-page.jpg" className="checkout-img" width="100%" alt="checkout" />
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default PreCheckout;