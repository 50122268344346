import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { setAddress, setAddressInTotal, setFormDirty } from "./set-address-reducer";
import { toast } from 'react-toastify';
import axios from "../../axios-interceptors";
import { State, City } from 'country-state-city';
import { useEffect, useState } from "react";
import Select from 'react-select';

const AddEditAddress = () => {

    const dispatch = useDispatch();
    const formData = useSelector((state) => state.address.value);
    const addressFormDirty = useSelector((state) => state.address.dirty);
    const profileData = useSelector((state) => state.loggedIn.value.profileData);
    const [stateCode, setStateCode] = useState("");
    const [state, setState] = useState({});
    const [city, setCity] = useState({});
    // const addressTypes = [
    //     { name: "Home", value: "home" },
    //     { name: "Work", value: "work" },
    //     { name: "Other", value: "other" },
    // ]

    const onChangeValue = (evt) => {
        let val = evt.target.value;
        if (evt.target.name === "mobile" || evt.target.name === "pincode") {
            val = val.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }
        if (evt.target.id === "streetAddress2") {
            val = `${val} | ${formData.addr_line2.split(" | ")[1] || ""}`;
        }
        if (evt.target.id === "landmark") {
            val = `${formData.addr_line2.split(" | ")[0] || ""} | ${val}`;
        }
        dispatch(setAddress({ name: evt.target.name, value: val }));
        dispatch(setFormDirty(true));
    }

    const onChangeState = (evt) => {
        setState(evt);
        setCity({});
        dispatch(setAddress({ name: 'state', value: evt.value }));
        dispatch(setAddress({ name: 'city', value: "" }));
        dispatch(setFormDirty(true));
    }

    const onChangeCity = (evt) => {
        setCity(evt);
        dispatch(setAddress({ name: 'city', value: evt.value }));
        dispatch(setFormDirty(true));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const nonLoggedInEmail = localStorage.getItem("non-loggedIn-email");
        let obj = { ...formData, email: profileData?.email || "" };
        if (nonLoggedInEmail) obj = { ...obj, email: nonLoggedInEmail };
        if (!formData.id) {
            axios.post(`addresses/create`, obj).then(res => {
                if (res.status === "success") {
                    dispatch(setFormDirty(false));
                    dispatch(setAddressInTotal(res.data));
                    toast.success('Address added!', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }).catch(err => {
                console.log(err);
                toast.error(err, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        } else {
            axios.put(`addresses/${formData.id}`, obj).then(res => {
                if (res.status === "success") {
                    dispatch(setFormDirty(false));
                    dispatch(setAddressInTotal(formData));
                    toast.success(res.data ? res.data : 'Address updated!', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }).catch(err => {
                toast.error(err, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        }
    }

    const getStates = () => {
        let arr = [];
        let states = State.getStatesOfCountry("IN");
        for (let s of states) {
            let obj = { value: s.name, label: s.name };
            arr = [...arr, obj];
        }
        return arr;
    }

    const getCities = () => {
        let arr = [];
        if (stateCode) {
            let cities = City.getCitiesOfState("IN", stateCode);
            for (let c of cities) {
                let obj = { value: c.name, label: c.name };
                arr = [...arr, obj];
            }
        }
        return arr;
    }

    useEffect(() => {
        if (formData?.state) {
            setState(getStates().find(s => s.value === formData.state));
            setStateCode(State.getStatesOfCountry("IN")?.find(i => i.name === formData?.state)?.isoCode);
        }
    }, [formData?.state]);

    useEffect(() => {
        if (formData?.city) {
            setCity(getCities().find(c => c.value === formData.city));
        }
    }, [formData, stateCode]);

    return (
        <form onSubmit={onSubmit} className="form-border">
            <h4 className="fs-25 text-center mt-6 mb-6 fw-600 color-black">{!formData?.id ? "Add New Address" : "Edit Address"}</h4>
            <div className="mb-3">
                <label className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">Full name (First and last name)</label>
                <Row>
                    <Col md={12} className="mb-md-0 mb-4">
                        <input type="text" className="form-control border-0" id="fname" name="first_name" required autoComplete="off"
                            value={formData?.first_name} onChange={onChangeValue} />
                    </Col>
                </Row>
            </div>
            <div className="mb-2">
                <Row>
                    <Col md={12} className="col-md-12 mb-md-0 mb-4">
                        <label className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">Flat, House no., Building, Company, Apartment</label>
                        <input type="text" className="form-control border-0 mb-3" id="streetAddress" name="addr_line1" required autoComplete="off"
                            value={formData?.addr_line1} onChange={onChangeValue} />
                    </Col>
                </Row>
            </div>
            <div className="mb-2">
                <Row>
                    <Col md={12} className="col-md-12 mb-md-0 mb-4">
                        <label className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">Area, Street, Sector, Village</label>
                        <input type="text" className="form-control border-0 mb-3" id="streetAddress2" name="addr_line2" required autoComplete="off"
                            value={formData?.addr_line2?.split(" | ")?.[0] || ""} onChange={onChangeValue} />
                    </Col>
                </Row>
            </div>
            <div className="mb-2">
                <Row>
                    <Col md={12} className="col-md-12 mb-md-0 mb-4">
                        <label className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">Landmark</label>
                        <input type="text" className="form-control border-0 mb-3" id="landmark" name="addr_line2" autoComplete="off"
                            value={formData?.addr_line2?.split(" | ")?.[1] || ""} onChange={onChangeValue} />
                    </Col>
                </Row>
            </div>
            <div className="mb-3">
                <Row>
                    <Col md={6} className="mb-md-0 mb-4">
                        <label htmlFor="state" className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">State</label>
                        <input className="form-control border-0 req-hidden-field" id="state" name="state" required
                            value={formData?.state} onChange={() => {}} />
                        <Select
                            className="delivery-address"
                            value={state}
                            onChange={onChangeState}
                            options={getStates()}
                        />
                    </Col>
                    <Col md={6} className="mb-md-0 mb-4">
                        <label htmlFor="city" className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">City</label>
                        <input className="form-control border-0 req-hidden-field" id="city" name="city" required
                            value={formData?.city} onChange={() => {}} />
                        <Select
                            className="delivery-address"
                            value={city}
                            onChange={onChangeCity}
                            options={getCities()}
                        />
                    </Col>
                </Row>
            </div>
            <div className="mb-4">
                <Row>
                    {/* <Col md={6} className="mb-md-0 mb-4">
                        <input type="email" className="form-control border-0" id="email" name="email" placeholder="Email" required
                            value={formData.email} onChange={onChangeValue} />
                    </Col> */}
                    <Col md={6} className="mt-4">
                        <label htmlFor="zip" className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">zip code</label>
                        <input type="text" className="form-control border-0" id="zip" name="pincode" required minLength={"6"} maxLength={"6"}
                            value={formData?.pincode} onChange={onChangeValue} pattern="^[1-9][0-9]{5}$" autoComplete="off" />
                    </Col>
                    <Col md={6} className="mt-4">
                        <label htmlFor="mobile" className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">Phone number</label>
                        <input type="text" className="form-control border-0" id="mobile" name="mobile" minLength={"10"} maxLength={"10"} required
                            value={formData?.mobile} onChange={onChangeValue} autoComplete="off" />
                    </Col>
                </Row>
            </div>
            {/* <div className="mb-3">
                <Row>
                    <Col md={12}>
                        <label className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">Address Type</label>
                        <div>
                            {
                                addressTypes.map(a => (
                                    <label className="radio-inline add-redio mr-5" htmlFor={a.value} key={a.value}>
                                        <input type="radio" required name="type" id={a.value} value={a.value} checked={formData.type === a.value} onChange={onChangeValue} />
                                        <span className="ml-2 radio-btn-pos">{a.name}</span>
                                    </label>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
            </div> */}
            <div className="mt-6 mb-6">
                <button type="submit" name="save" className="btn btn-secondary bg-hover-primary border-hover-primary px-7" disabled={!addressFormDirty}>Submit</button>
                {/* <button type="button" className="btn" onClick={handleClose}>Cancel</button> */}
            </div>
        </form>
    )
}

export default AddEditAddress;