import { Link } from "react-router-dom";
import "./home.css";
import { Row, Col } from "react-bootstrap";
import { InstagramImgs, BritacelIcons } from "../../links";
import Slider from "react-slick";
import { FeedbackSlickSettings, SlickArrowLeft, SlickArrowRight } from "../../shared/slick-settings";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../axios-interceptors";
import { SortAndMap } from "../../shared/shuffle-array";
import DiscountPopup from './discount-popup';
import { useSelector } from "react-redux";

const Home = () => {
    const [products, setProducts] = useState([]);
    const [featureProducts, setFeatureProducts] = useState([]);
    const [testiminials, setTestiminials] = useState([]);
    const crmData = useSelector(state => state.loggedIn.value.crmData);
    const [showDiscountPopup, setShowDiscountPopup] = useState(false);
    const [discountObj, setDiscountObj] = useState({});
    const [checkLg, setCheckLg] = useState(true);
    const [videoFullScreen, setVideoFullScreen] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const vidRef = useRef();
    const [banner, setBanner] = useState("banner1");
    const [productImgsSlickSettings, setProductImgsSlickSettings] = useState({
        slidesToShow: 6,
        infinite: true,
        autoplay: true,
        dots: false,
        arrows: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            { breakpoint: 1400, settings: { slidesToShow: 6 } },
            { breakpoint: 1300, settings: { slidesToShow: 5 } },
            { breakpoint: 992, settings: { slidesToShow: 5 } },
            { breakpoint: 768, settings: { slidesToShow: 4 } },
            { breakpoint: 576, settings: { slidesToShow: 1 } }
        ]
    });

    window.addEventListener('resize', () => {
        if (window.innerHeight == window.screen.height) {
            setVideoFullScreen(true);
        } else {
            setVideoFullScreen(false);
        }
    });

    const getDiscountedPrice = (p) => {
        let price = p.price;
        if (p.discount_percent) price = p.price - (p.price * p.discount_percent / 100);
        return Math.ceil(price);
    }

    const isVideo = (extension) => extension === ("mp4" || "m4a" || "f4v" || "m4b" || "mov");

    useEffect(() => {
        axios.get(`products`)
            .then((res) => {
                setProducts(SortAndMap(res));
                let shownProducts = res.filter(i => (i.show_product == true || i.show_product == 1));
                if (shownProducts.length <= 6) setProductImgsSlickSettings({ ...productImgsSlickSettings, infinite: false });
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (crmData?.length > 0) {
            setFeatureProducts(crmData.filter(i => i?.references?.key == "landing") || []);
            setTestiminials(crmData.filter(i => i?.references?.key == "testiminials") || []);
            const _discountObj = crmData.find(i => i?.references?.key == "discount_banner");
            if (_discountObj) {
                setDiscountObj(_discountObj);
                setShowDiscountPopup(true);
            }
        }
    }, [crmData]);

    const handleResize = () => {
        if (window.innerWidth < 992) {
            setCheckLg(false);
        } else {
            setCheckLg(true);
        }
    }

    // useEffect(() => {
    //     if (vidRef?.current) {
    //         var video = document.getElementById('vid');
    //         video.volume = 0.3;
    //     }
    // }, [vidRef?.current]);

    useEffect(() => {
        handleResize();
    }, []);

    window.addEventListener("resize", handleResize, false);

    let playState = null;

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (!entry.isIntersecting) {
                vidRef.current?.pause();
                playState = false;
            } else {
                vidRef.current?.play();
                playState = true;
            }
        });
    }, {});

    const onVisibilityChange = () => {
        if (document.hidden || !playState) {
            vidRef.current?.pause();
        } else {
            vidRef.current?.play();
        }
    };

    // document.addEventListener("visibilitychange", onVisibilityChange);

    const toggleSequence = () => {
        if (document.getElementById("bannerImg")) {
            if (document.getElementById("bannerImg").style.backgroundImage === '' || document.getElementById("bannerImg").style.backgroundImage === 'url("images/banner.jpg")') {
                document.getElementById("bannerImg").style.backgroundImage = 'url("images/banner1.jpg")';
                setBanner("banner2");
            } else if (document.getElementById("bannerImg").style.backgroundImage === 'url("images/banner1.jpg")') {
                document.getElementById("bannerImg").style.backgroundImage = 'url("images/banner2.jpg")';
                setBanner("banner3");
            } else if (document.getElementById("bannerImg").style.backgroundImage === 'url("images/banner2.jpg")') {
                document.getElementById("bannerImg").style.backgroundImage = 'url("images/banner.jpg")';
                setBanner("banner1");
            }
        }
    }

    // useEffect(() => {
    //     if (vidRef && vidRef.current) observer.observe(vidRef.current);
    // }, [featureProducts]);

    useEffect(() => {
        const bannerInterval = setInterval(toggleSequence, 7000);
        return () => {
            clearInterval(bannerInterval);
        }
    }, []);
    console.log({ products })
    return (
        <>
            <div style={{ overflowX: "hidden" }}>
                <section className="overflow-hidden vh-100-video d-flex align-items-end bg-video">
                    <div className={`banner-img`} id="bannerImg">
                        {banner === "banner1" && <p className="banner-txt" id="imgText">FORMULATED IN SWITZERLAND<br />MADE IN INDIA</p>}
                        {banner === "banner2" && <p className="img-text banner-txt1" id="imgText">EXPERIENCE the<br />LUXURY of our<br />HOMECARE brand</p>}
                        {banner === "banner3" && <p className="img-text banner-txt2" id="imgText">Innovative CHEMISTRIES<br /> to PROTECT your HEALTH</p>}
                    </div>
                </section>
                <section className="reveal mobile-padding-shop" style={{ padding: "70px 0 50px" }}>
                    <div className="container-fluid mobile-no-mb" style={{ paddingLeft: 0, paddingRight: "15px", marginBottom: "50px" }}>
                        <Row className="mb-md-6 mb-8">
                            <Col>
                                <h2 className="fs-42 fadeInUp animated text-center font-freightbig-normal color-black">SHOP NOW</h2>
                            </Col>
                        </Row>
                    </div>
                    {products?.length > 0 && <div className="container-fluid mob-prod-imgs">
                        <Slider {...productImgsSlickSettings} className="products-slider imgs-slider">
                            {products.map(p => (
                                    
                                    ((p.show_product == true || p.show_product == 1) && p?.categories?.title === "All Products") &&
                                    <div className="card border-0 reveal fadeInUp animated" key={p.id}>
                                            <>
                                                {console.log({ p })}
                                            </>    
                                        <Link className="product-info" to={`/product-detail/${p.id}`}>
                                            <div className="position-relative">
                                                <img src={p.images?.path} className={`img-shop ${(!p.status || p.status == 0) && 'unavilable-product'}`} alt={p.name?.split(" | ")?.[0]} />
                                                {(!p.status || p.status == 0) && <div className="out-of-stock-sec text-uppercase">Out of Stock</div>}
                                            </div>
                                            <div className="card-body px-0 pt-4 text-center height-135">
                                                <p className="card-text font-weight-bold fs-16 mb-1 text-decorated">
                                                    <span>₹{getDiscountedPrice(p)}</span>
                                                    {p.discount_percent &&
                                                        <>
                                                            <span className="actual-price">₹{p.price}</span>
                                                            {/* <span className="discount-percent">{p.discount_percent}% off</span> */}
                                                            <span className="badge badge-primary fs-15 font-weight-500 py-1 px-2 ml-2">{p.discount_percent}% OFF</span>
                                                        </>
                                                    }
                                                </p>
                                                <h2 className="card-title product-heading heading-wrap line-clamp-2 text-decorated" title={p.name?.split(" | ")?.[0]}>{p.name?.split(" | ")?.[0]}</h2>
                                                <span className="sub-heading-product heading-wrap line-clamp-1 text-decorated" title={p.name?.split(" | ")?.[1]}>{p.name?.split(" | ")?.[1]}</span>
                                            </div>
                                        </Link>
                                        <Link to={`/product-detail/${p.id}`} target="_blank" className="font-weight-600 brita-btn">
                                            Order Now
                                        </Link>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>}
                </section>
                {
                    featureProducts?.map((p, i) => (
                        <section className={`reveal sec${i}`} key={p.id}>
                            <div className="container-fluid px-0" style={{ backgroundColor: "#EDF1F0" }}>
                                <Row className="align-items-center mx-0">
                                    {(i % 2 === 0 || !checkLg) && <Col lg={6} className="fadeInUp animated" style={{ backgroundImage: `url(${p?.images?.path})`, backgroundPosition: "center", backgroundSize: "cover", padding: 0 }}>
                                        <div className="mh-xl-640 reveal">
                                            {isVideo(p?.images?.extension) && <video id="vid" className={`${!videoFullScreen && "video-cover"}`} ref={vidRef} height="100%" width="100%" loop muted autoPlay playsInline controls={showControls}
                                                onMouseOver={() => setShowControls(true)} onMouseOut={() => setShowControls(true)}>
                                                <source src={p?.images?.path} />
                                            </video>}
                                        </div>
                                    </Col>}
                                    <Col lg={6} className="py-lg-6 pl-xl-13 pl-lg-6 pl-3 fadeInUp animated">
                                        {p?.products?.discount_percent && <div className="d-flex align-items-center fs-15 color-black text-uppercase font-weight-600  letter-spacing-01 mt-4 mb-4">
                                            Special offer<span className="badge badge-primary fs-15 font-weight-500 py-1 px-2 ml-2">{p.products?.discount_percent}% OFF</span>
                                        </div>}
                                        <h2 className="font-freightbig-normal fs-48 color-black" dangerouslySetInnerHTML={{ __html: p?.title }}></h2>
                                        {/* <p className="mb-2 fs-16 fw-600" dangerouslySetInnerHTML={{ __html: p?.products?.description }}></p> */}
                                        <p className="fs-18 mw-460 mb-4 font-garamond-semiBold" dangerouslySetInnerHTML={{ __html: p?.description }}></p>
                                        <Link to={`/product-detail/${p?.product_id}`} className="btn btn-md rounded btn-light hover-white bg-hover-secondary try-btn-beauty border-hover-secondary">Shop Now!</Link>
                                    </Col>
                                    {(i % 2 !== 0 && checkLg) && <Col lg={6} className="fadeInUp animated" style={{ backgroundImage: `url(${p?.images?.path})`, backgroundPosition: "center", backgroundSize: "cover", padding: 0 }}>
                                        <div className="reveal mh-xl-640">
                                            {isVideo(p?.images?.extension) && <video id="vid" ref={vidRef} className={`${!videoFullScreen && "video-cover"}`} height="100%" width="100%" loop muted autoPlay playsInline controls={showControls}
                                                onMouseOver={() => setShowControls(true)} onMouseOut={() => setShowControls(true)}>
                                                <source src={p?.images?.path} />
                                            </video>}
                                        </div>
                                    </Col>}
                                </Row>
                            </div>
                        </section>
                    ))
                }
                {/* <section className="reveal" data-animated-id="4">
                <Row className="mx-0">
                    <Col lg={6} className="py-lg-17 order-2 order-lg-1 fadeInUp animated" data-animate="fadeInUp" style={{ backgroundColor: "#fff" }}>
                        <div className="mw-lg-570 ml-auto py-8">
                            <div className="d-flex align-items-center fs-15 color-black text-uppercase font-weight-600 letter-spacing-01 mb-4">
                                Special offer<span className="badge badge-primary fs-15 font-weight-500 py-1 px-2 ml-2">10% OFF</span>
                            </div>
                            <h2 className="fs-48 font-freightbig-normal color-black">FARM to FORK now made Healthy and Clean</h2>
                            <p className="mb-2 fs-16 fw-600">Another cracker of our signature collection is our eco-friendly vegetable and fruits liquid cleaner. </p>
                            <p className="mb-0 fs-18 pt-1 font-garamond-semiBold">Straight from the Royal kitchen to yours, Ella’s coveted bottle of vegetable &amp; fruit wash is now yours to buy!</p>
                            <Link to="/product-detail/1" onClick={refreshPage} className="btn btn-md rounded btn-light mt-6 hover-white bg-hover-secondary try-btn-beauty border-hover-secondary">Get to know more! </Link>
                        </div>
                    </Col>
                    <Col lg={6} className="d-flex align-items-center justify-content-center padding-0 order-lg-2" style={{ backgroundImage: "url('images/cleaning02.jpg')", backgroundPosition: "center", backgroundSize: "cover", height: "431px" }}>
                        <iframe src="https://www.youtube-nocookie.com/embed/oUDcPcVPXaI?autoplay=1&mute=1&loop=1&playlist=oUDcPcVPXaI&rel=0" style={{ position: "relative", height: "100%", width: "100%" }} frameBorder="0" allowFullScreen></iframe>
                    </Col>
                </Row>
            </section> */}
                <section className="pt-10 pt-lg-13 pb-10 pb-lg-10 reveal mt-8" style={{ backgroundColor: "#000" }}>
                    <div className="container container-xl">
                        <Row>
                            <Col style={{ textAlign: "center" }} className="fadeInUp animated testimonial">
                                <Slider {...FeedbackSlickSettings} className="testimonials-slider">
                                    {
                                        testiminials.map(f => (
                                            <React.Fragment key={f.id}>
                                                <p className="card-text fs-18 font-weight-500 feedback-text mx-auto lh-13 font-garamond-semiBold" style={{ maxWidth: "660px" }}
                                                    dangerouslySetInnerHTML={{ __html: f.description }}>
                                                </p>
                                                {/* <h4 className="fs-18 text-uppercase letter-spacing-01 font-freightbig color-white" style={{ fontWeight: 500 }}>
                                                    {f.author}
                                                </h4>
                                                <span className="fw-600">{f.position}</span> */}
                                            </React.Fragment>
                                        ))
                                    }
                                </Slider>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="pt-lg-12 pt-10 reveal mob-pt-1">
                    <div className="container-fluid">
                        <Row className="align-items-end">
                            <Col md={12} className="mb-md-6 text-center">
                                <h2 className="fs-42 fadeInUp color-black font-freightbig-normal animated" style={{ marginBottom: "25px" }}>Instagram</h2>
                            </Col>
                            {
                                InstagramImgs.map((img, i) => (
                                    <Col xl={3} lg={3} md={3} sm={3} xs={6} className={`imgs-slider insta-imgs ${i % 2 === 0 ? 'mob-pr' : 'mob-pl'}`} key={img.name}>
                                        <Link to="#" className="hover-shine d-block insta-img fadeInUp animated" tabIndex="0">
                                            <img src={img.url} alt={img.name} width={"100%"} />
                                        </Link>
                                    </Col>
                                ))
                            }
                            <Col md={12} className="text-center">
                                <Link className="link-bg" to="https://www.instagram.com/britacelhealthcare/" target="_blank">
                                    <p className="fs-20 insta-para">@britacelhealthcare</p>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="pt-11 pb-xl-9 pb-5 mob-pt-0">
                    <div className="container container-xl change-image-size">
                        <Row>
                            {
                                BritacelIcons.map(icon => (
                                    <Col xl={2} md={4} key={icon.title}>
                                        <div className="card bg-transparent border-0 align-items-center text-center mb-xl-0 mb-6 mob-display-sketches">
                                            <div className="card-img">
                                                <img src={icon.src} alt={icon.title} />
                                            </div>
                                            <div className="card-body text-center px-1">
                                                <h3 className="card-title fw-600 color-black fs-16 font-garamond-Regular mb-2">{icon.title}</h3>
                                                <p className="cart-text fs-14 mb-0">{icon.text}</p>
                                                <span>{icon.subText}</span>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </section>
            </div>
            {showDiscountPopup && <DiscountPopup show={showDiscountPopup} handleClose={() => setShowDiscountPopup(false)} discountObj={discountObj} />}
        </>
    )
}

export default Home;