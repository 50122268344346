import { Link } from "react-router-dom";
import { Links, SocialLinks } from "../links";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from "react";
import axios from "../axios-interceptors";
import { toast } from 'react-toastify';

const Footer = () => {

    const [email, setEmail] = useState("");

    const subscribe = (e) => {
        e.preventDefault();
        axios.post(`subscribes/create`, { email }).then((res) => {
            console.log(res);
            if (res.status === "success") {
                setEmail("");
                toast.success('Subscription successful!', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        })
            .catch((err) => {
                console.log(err);
                toast.error("Subscription error!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            });
    }

    return (
        <footer className="pt-10 pt-lg-14 pb-11 footer bg-black">
            <div className="_container container-xl">
                <Row className="font-garamond-semiBold">
                    <Col className="mb-9 mb-lg-0" xs={12} sm={12} md={5} lg={5} xl={5} xxl={5}>
                        <h3 className="mb-2 fs-48 text-white font-freightbig-normal">Join The Britacel Community</h3>

                        <div id="error4"></div>
                        <form className="pt-4" id="userSubscribe" onSubmit={subscribe}>
                            <div className="input-group position-relative">
                                <input type="email" id="emailid" className="form-control bg-white rounded-left open-san" placeholder="Email" name="email"
                                    value={email} onChange={(e) => setEmail(e.target.value)} />
                                <button type="submit" id="btn-subscribe" className="btn btn-secondary gray-bg bg-hover-primary open-san border-hover-primary no-rounded-right">
                                    Subscribe
                                </button>
                            </div>
                        </form>
                    </Col>
                    <Col className="mb-lg-0" xs={12} sm={12} md={1} lg={1} xl={1} xxl={1}></Col>
                    <Col className="mb-7 mb-lg-0" xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                        <ul className="list-unstyled mb-0">
                            {
                                Links.slice(0, 5).map((link) => (
                                    <li className={link.className} key={link.name}>
                                        <Link className="text-white-link lh-175 text-decorated" to={link.url}>{link.name}</Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </Col>
                    <Col className="mb-8 mb-lg-0" xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                        <ul className="list-unstyled mb-0">
                            {
                                Links.slice(5, 8).map((link) => (
                                    <li className={link.className} key={link.name}>
                                        <Link className="text-white-link lh-175 text-decorated" to={link.url}>{link.name}</Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-0 mt-lg-16 align-items-center">
                    <Col className="col-12 col-md-6 col-lg-4 d-flex align-items-center order-2 order-lg-1 mt-4 mt-md-7 mt-lg-0" xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                        <ul className="list-inline fs-18 ml-3 mb-0">
                            {
                                SocialLinks.map((link) => (
                                    <li className={link.className} key={link.name}>
                                        <Link className="text-white-link" to={link.url} target="_blank">{link.icon}</Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </Col>
                    <Col className="text-md-center order-1 order-lg-2" xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <img src="images/logo-white.png" width="200px" alt="Britacel" />
                        <p className="mb-0 font-garamond-semiBold text-white-link" style={{ marginTop: "5px" }}>Copyright ©. All Rights Reserved.</p>
                    </Col>
                    <Col className="text-md-right order-3 mt-4 mt-md-7 mt-lg-0" xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                        <img src="images/icon-pay.png" alt="Pay" />
                    </Col>
                </Row>
            </div>
        </footer>
    )
}

export default Footer;