import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'loggedIn',
  initialState: {
    value: {
      loggedIn: false,
      profileUpdated: false,
      profileData: {},
      crmData: null,
      resetPwd: false,
    },
  },
  reducers: {
    isLoggedIn: (state, action) => {
      state.value.loggedIn = action.payload
    },
    profileUpdate: (state, action) => {
      state.value.profileUpdated = action.payload
    },
    setProfileData: (state, action) => {
      state.value.profileData = action.payload
    },
    setCrmData: (state, action) => {
      state.value.crmData = action.payload
    },
    setResetPwd: (state, action) => {
      state.value.resetPwd = action.payload
    },
  },
})

export const { isLoggedIn, profileUpdate, setProfileData, setCrmData, setResetPwd } = loginSlice.actions;

export default loginSlice.reducer;