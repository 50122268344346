import { Link } from "react-router-dom";
import "../Journal/journal.css";
import { Row, Col } from "react-bootstrap";
import "./contact-us.css";
import { SocialLinks } from "../../links";
import { useEffect, useState } from "react";
import axios from "../../axios-interceptors";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

const ContactUs = () => {
    const [enqForm, setEnqForm] = useState({ full_name: "", email: "", message: "" });
    const crmData = useSelector(state => state.loggedIn.value.crmData);
    const [contactObj, setContactObj] = useState(null);

    const onChange = (e) => {
        setEnqForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const addEnquiry = (e) => {
        e.preventDefault();
        axios.post(`enquiries/create`, enqForm).then((res) => {
            console.log(res);
            if (res.status === "success") {
                setEnqForm({ full_name: "", email: "", message: "" });
                toast.success('Enquiry submitted!', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        })
            .catch((err) => {
                console.log(err);
                toast.error("Enquiry couldn't be submitted!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            });
    }

    useEffect(() => {
        if (crmData?.length > 0) {
            const contactUsObj = crmData.find(i => i?.references?.key == "contact_us");
            setContactObj(contactUsObj);
        }
    }, [crmData]);

    return (
        <>
            {/* <section className="py-2 bg-gray-2" style={{ marginTop: "150px" }} data-animated-id="1">
                <div className="container container-xl">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-site py-0 d-flex justify-content-center">
                            <li className="breadcrumb-item">
                                <Link className="text-decoration-none text-body" to="/home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active pl-0 d-flex align-items-center" aria-current="page">Contact Us
                            </li>
                        </ol>
                    </nav>
                </div>
            </section> */}
            <section className="pt-lg-14 pt-md-10 pt-7 news-section" style={{ marginTop: "100px" }} data-animated-id="2">
                <div className="container container-xl">
                    <Row>
                        <Col lg={{ span: 8, offset: 2 }}>
                            <h2 className="text-center font-weight-bold font-freightbig-normal color-black" style={{ paddingBottom: "40px", fontSize: "70px" }}>
                                CONTACT US
                            </h2>
                        </Col>
                        <Col className="mb-6 mb-lg-0" lg={6}>
                            <div className="hover-shine">
                                <img className="w-100 contact-img" src={contactObj?.images?.path} alt="contact" />
                            </div>
                        </Col>
                        <Col lg={6} className="contact-us-letter">
                            {/* <img className="thankyou-img" src="images/Thank You Note.jpg" alt="" /> */}
                            <p>DEAR PATRON,</p>
                            <p>WE HOPE THAT YOU'RE WELL!</p>
                            <p>IT'S AN HONOR TO PRESENT OUR HEALTH CARE PRODUCTS TO YOU, TODAY.</p>
                            <p>ALL OUR PRODUCTS ARE WELL CRAFTED WITH AN OBSESSIVE ATTENTION TO INNOVATION AND DEDICATION FOR YOUR WELL BEING.</p>
                            <p>THANK YOU FOR CHOOSING US TO PROTECT YOU!</p>
                            <p>WISHING YOU GOOD HEALTH AND HAPPINESS ALWAYS.</p>
                            <p>INFINITE X'S AND O'S</p>
                            <p className="font-italic mb-0">@britacelhealthcare</p>
                            <p className="font-italic">www.britacelhealthcare.com</p>
                        </Col>
                        <Col lg={6} className="mt-6">
                            <div className="contact-details-update font-garamond-semiBold" dangerouslySetInnerHTML={{ __html: contactObj?.description }}>
                            </div>
                        </Col>
                        <Col lg={6} className="mt-6">
                            <h3 className="fs-34 mb-4 font-freightbig-normal color-black" style={{ fontStyle: "italic" }}>
                                Write to us
                            </h3>
                            <form onSubmit={addEnquiry}>
                                <div className="row mb-6">
                                    <div className="col-sm-6 mb-6 mb-sm-0">
                                        <input type="text" className="form-control border-0" placeholder="Full Name" required name="full_name"
                                            value={enqForm.full_name} onChange={onChange} />
                                    </div>
                                    <div className="col-sm-6">
                                        <input type="email" className="form-control border-0" placeholder="Email" required name="email"
                                            value={enqForm.email} onChange={onChange} />
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <textarea className="form-control border-0" rows="4" placeholder="Message" name="message" value={enqForm.message} onChange={onChange} />
                                </div>
                                <button type="submit" className="btn btn-secondary px-7 bg-hover-primary border-hover-primary" name="savec">Submit</button>
                            </form>
                        </Col>
                        <Col lg={{ span: 10, offset: 1 }}>
                            <Row style={{ padding: "70px 0 0px" }}>
                                <Col lg={4} className="social-contact">
                                    <div className="mail-contact-footer-dev">
                                        <h2>Follow Us</h2>
                                        <ul className="list-inline fs-18 ml-3 mb-0">
                                            {
                                                SocialLinks.map((link) => (
                                                    <li className={link.className} key={link.name}>
                                                        <Link className="text-black-link" to={link.url} target="_blank">{link.icon}</Link>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mail-contact-footer-dev border-contact">
                                        <h2>Newsletter Signup</h2>
                                        <form>
                                            <div className="input-group position-relative">
                                                <input type="email" className="form-control bg-white rounded-left open-san" name="email" placeholder="Email" />
                                                <button type="submit" name="subcc" className="btn btn-secondary gray-bg bg-hover-primary open-san border-hover-primary">
                                                    Subscribe</button>
                                            </div>
                                        </form>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mail-contact-footer-dev">
                                        <h2>Read our Journal</h2>
                                        <Link to="/journal" className="btn-contact-journal text-decorated">Journal</Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default ContactUs;