import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const DiscountBanner = ({ handleClose, discountObj }) => {
    const navigate = useNavigate();
    return (
        <div className="p-0" id="discountBanner">
            <span onClick={() => navigate("/shop")} dangerouslySetInnerHTML={{ __html: discountObj.description }}></span>
            <button className="btn p-0 pull-right close-banner" type="button" onClick={handleClose}><AiOutlineClose /></button>
        </div>
    )
}

export default DiscountBanner;