import { Link, useParams } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import {
  SingleProductImgsSlickSettings,
  SlickArrowLeft,
  SlickArrowRight,
} from "../../shared/slick-settings";
import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import "./product-detail.css";
import { FaMinus, FaPlayCircle, FaPlus } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { addProductToCart, removeProductFromCart } from "./add-to-cart-reducer";
import axios from "../../axios-interceptors";
import { showCart } from "./show-cart-reducer";
import { SortAndMap } from "../../shared/shuffle-array";
import { toast } from "react-toastify";
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineClose,
} from "react-icons/ai";

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [qty, setQty] = useState(1);
  const [selectPack, setSelectPack] = useState('pack_1');
  const cartvalue = useSelector((state) => state.addToCart.value);
  const dispatch = useDispatch();
  const [productInCart, setProductInCart] = useState(null);
  const profileData = useSelector((state) => state.loggedIn.value.profileData);
  const [allProducts, setAllProducts] = useState([]);
  const [otherProducts, setOtherProducts] = useState([]);
  const isVideo = (extension) =>
    extension === ("mp4" || "m4a" || "f4v" || "m4b" || "mov");
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [currZoomImg, setCurrZoomImg] = useState(null);
  const [videoFullScreen, setVideoFullScreen] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [accordionData, setAccordionData] = useState([
    { header: "Product Detail", key: "product_details" },
    { header: "How To Use", key: "usage" },
    { header: "Fragrance Notes", key: "fragnance_notes" },
  ]);
  const [productImgsSlickSettings, setProductImgsSlickSettings] = useState({
    slidesToShow: 6,
    infinite: true,
    autoplay: true,
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      { breakpoint: 1400, settings: { slidesToShow: 6 } },
      { breakpoint: 1300, settings: { slidesToShow: 5 } },
      { breakpoint: 992, settings: { slidesToShow: 5 } },
      { breakpoint: 768, settings: { slidesToShow: 4 } },
      { breakpoint: 576, settings: { slidesToShow: 1 } },
    ],
  });
  const [allProductImgsSlickSettings, setAllProductImgsSlickSettings] =
    useState({
      slidesToShow: 5,
      vertical: true,
      autoplay: false,
      dots: false,
      arrows: false,
      mobileFirst: true,
      asNavFor: ".slider-for",
      className: "slider-nav mx-n1 mx-xl-0",
      focusOnSelect: true,
      infinite: false,
      responsive: [{ breakpoint: 1200, settings: { vertical: false } }],
    });

  const checkIfZero = (product) => {
    if (qty <= 0) setQty(1);
    if (product?.size < qty) setQty(product?.size);
  };

  console.log({ productInCart });
  const addToCart = () => {
    const checkSamplesInCart = cartvalue?.find(
      (i) => i?.categories?.title === "Samples"
    );
    if (checkSamplesInCart) {
      toast.error("Sample cannot be combined with other products/samples", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    let _qty = 1;
    if (productInCart) {
      _qty += productInCart?.qty;
    }
    const obj = {
      ...product,
      qty: _qty,
      price: getDiscountedPriceForPack(product, selectPack),
      selectPack,
    };
    let arr = localStorage.getItem(`cart-value${profileData?.id || ""}`)
      ? JSON.parse(localStorage.getItem(`cart-value${profileData?.id || ""}`))
      : [];
    const productAddedAlready = cartvalue.find(
      (i) => i.id == id && i.selectPack == selectPack
    );
    if (productAddedAlready) {
      dispatch(removeProductFromCart({ id, selectPack }));
      console.log({ arr });

      const newPackArrayIndex = arr.findIndex(
        (i) => i.id == id && i.selectPack === selectPack
      );
      console.log({ newPackArrayIndex });
      arr = arr.filter((i, index) => index != newPackArrayIndex);
      console.log({ arr });
    }
    dispatch(addProductToCart(obj));
    arr = [...arr, obj];
    localStorage.setItem(
      `cart-value${profileData?.id || ""}`,
      JSON.stringify(arr)
    );
    dispatch(showCart(true));
  };

  const addSupremeToCart = (s) => {
    const checkSamplesInCart = cartvalue?.find(
      (i) => i?.categories?.title === "Samples"
    );
    if (checkSamplesInCart) {
      toast.error("Sample cannot be combined with other products/samples", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    const isAdded = cartvalue.filter((i) => i.id === s.id) || [];
    const obj = { ...s, qty: isAdded.length + 1, price: getDiscountedPrice(s) };
    let arr = localStorage.getItem(`cart-value${profileData?.id || ""}`)
      ? JSON.parse(localStorage.getItem(`cart-value${profileData?.id || ""}`))
      : [];
    const productAddedAlready = cartvalue.find((i) => i.id === s.id);
    if (productAddedAlready) {
      dispatch(removeProductFromCart(s.id));
      arr = arr.filter((i) => i.id !== s.id);
    }
    dispatch(addProductToCart(obj));
    arr = [...arr, obj];
    localStorage.setItem(
      `cart-value${profileData?.id || ""}`,
      JSON.stringify(arr)
    );
    dispatch(showCart(true));
  };

  const setAddedQty = (_qty) => {
    setQty(_qty);
  };

  const getDiscountedPrice = (p) => {
    let price = p.price;
    if (p.discount_percent)
      price = p.price - (p.price * p.discount_percent) / 100;
    return Math.ceil(price);
  };

  const getDiscountedPriceForPack = (p, pack_name) => {
    let price = p[`${pack_name}_price`];
    const discountPrice = p[`${pack_name}_discount_percent`];
    console.log({ price:price, discountPrice })
    if (discountPrice) price = p[`${pack_name}_price`] - (p[`${pack_name}_price`] * +discountPrice) / 100;
    return Math.ceil(price);
  };

  const hasSupreme = (i) => {
    let product_title = product?.name?.split(" | ")?.[0]?.toUpperCase();
    let supreme_titles = i?.product_details
      ?.split("<p>")?.[1]
      ?.split("</p>")?.[0]
      ?.split(" | ");
    let exists = supreme_titles?.some((t) => t.toUpperCase() === product_title);
    return exists;
  };

  const supremeProducts = allProducts?.filter(
    (i) => i.categories?.title !== "All Products" && hasSupreme(i)
  );

  const openImgPopup = (index) => {
    setShowImagePopup(true);
    setCurrZoomImg({ ...product?.productImages?.[index], index });
  };

  const closeImgPopup = () => {
    setShowImagePopup(false);
    setCurrZoomImg(null);
  };

  const goToNextImg = () => {
    let index = currZoomImg?.index;
    if (index === product?.productImages?.length - 1) {
      setCurrZoomImg({ ...product?.productImages?.[0], index: 0 });
    } else {
      setCurrZoomImg({
        ...product?.productImages?.[index + 1],
        index: index + 1,
      });
    }
  };

  const goToPrevImg = () => {
    let index = currZoomImg?.index;
    let len = product?.productImages?.length;
    if (index === 0) {
      setCurrZoomImg({ ...product?.productImages?.[len - 1], index: len - 1 });
    } else if (index > 0) {
      setCurrZoomImg({
        ...product?.productImages?.[index - 1],
        index: index - 1,
      });
    }
  };

  const imgBlock = (s) => (s?.productImages?.length > 2 ? 6 : 5);

  const prodDescBlock = (s) => (s?.productImages?.length > 2 ? 6 : 7);

  const imgSize = (s) => (s.productImages?.length > 2 ? 3 : 5);

  window.addEventListener("resize", () => {
    if (window.innerHeight == window.screen.height) {
      setVideoFullScreen(true);
    } else {
      setVideoFullScreen(false);
    }
  });

  useEffect(() => {
    const product = cartvalue.find(
      (i) => i.id == id && i.selectPack == selectPack
    );
    setProductInCart(product || null);
  }, [cartvalue, selectPack]);

  useEffect(() => {
    axios
      .get(`products/${id}`)
      .then((res) => {
        setProduct(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    if (product?.id) {
      if (!product?.product_details && product?.usage) {
        setAccordionData([
          ...accordionData.filter((a) => a.key !== "product_details"),
        ]);
      } else if (
        !product?.product_details &&
        !product?.usage &&
        product?.fragnance_notes
      ) {
        setAccordionData([
          ...accordionData.filter((a) => a.key === "fragnance_notes"),
        ]);
      } else if (
        product?.product_details &&
        !product?.usage &&
        product?.fragnance_notes
      ) {
        setAccordionData([...accordionData.filter((a) => a.key !== "usage")]);
      }
      if (product?.productImages?.length > 0) {
        setAllProductImgsSlickSettings({
          ...allProductImgsSlickSettings,
          slidesToShow: product?.productImages?.length,
        });
      }
    }
  }, [product?.id]);

  useEffect(() => {
    axios
      .get(`products`)
      .then((res) => {
        setAllProducts(res);
        const others = res.filter(
          (i) =>
            i.id != id &&
            (i.show_product == true || i.show_product == 1) &&
            i?.categories?.title === "All Products"
        );
        if (others.length <= 6)
          setProductImgsSlickSettings({
            ...productImgsSlickSettings,
            infinite: false,
          });
        setOtherProducts(SortAndMap(others));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const getImgTitle = (s, i) => {
    let titles = s?.usage?.replace(/(<([^>]+)>)/gi, "")?.split("#");
    return titles[i]?.split(" | ")[0];
  };

  const getImgSuffix = (s, i) => {
    let titles = s?.usage?.replace(/(<([^>]+)>)/gi, "")?.split("#");
    return titles[i]?.split(" | ")[1];
  };

  const setGalleryHeight = () => {
    return (
      window?.innerHeight -
        document.getElementById("headerWrapper")?.offsetHeight || 0
    );
  };

  const handleSelectCart = (packId) => {
    setSelectPack(packId);
  };
  return (
    <>
      {/* <section className="py-2 bg-gray-2" style={{ marginTop: "150px" }} data-animated-id="1">
                <div className="container container-xl">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-site py-0 d-flex justify-content-center">
                            <li className="breadcrumb-item">
                                <Link className="text-decoration-none text-body" to="/home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active pl-0 d-flex align-items-center" aria-current="page">{product?.name}
                            </li>
                        </ol>
                    </nav>
                </div>
            </section> */}
      <section
        className="pt-11 pb-5 product-details-layout-5"
        style={{ marginTop: "80px" }}
      >
        <div className="container container-xl">
          <Row>
            <Col
              md={6}
              className="mb-8 mb-md-0 primary-gallery summary-sticky pr-xl-9"
              id="summary-sticky"
            >
              <div className="primary-summary-inner">
                <div
                  className={`galleries-product galleries-product-01 position-relative d-flex single-slider ${
                    product?.productImages?.length === 0 && "single-img"
                  }`}
                  style={{ maxHeight: setGalleryHeight() }}
                >
                  <Slider
                    {...SingleProductImgsSlickSettings}
                    asNavFor={slider2}
                    ref={(slider) => setSlider1(slider)}
                  >
                    {product?.productImages?.length > 0 ? (
                      product?.productImages.map((img, i) => (
                        <div
                          className="card slider-card-sec p-0 rounded-0 border-0 gallery"
                          key={img.image_id}
                        >
                          {isVideo(img?.images?.extension) ? (
                            <video
                              className={`${!videoFullScreen && "video-cover"}`}
                              height="100%"
                              width="100%"
                              muted
                              autoPlay
                              playsInline
                              controls={showControls}
                              onMouseOver={() => setShowControls(true)}
                              onMouseOut={() => setShowControls(true)}
                            >
                              <source src={img.images?.path} />
                            </video>
                          ) : (
                            <a
                              className="card-img"
                              onClick={() => openImgPopup(i)}
                            >
                              <img
                                src={img.images?.path}
                                alt={img.image_id}
                                className="w-100"
                              />
                            </a>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="card p-0 rounded-0 border-0 gallery">
                        {isVideo(product?.images?.extension) ? (
                          <video
                            className={`${!videoFullScreen && "video-cover"}`}
                            height="100%"
                            width="100%"
                            muted
                            autoPlay
                            playsInline
                            controls={showControls}
                            onMouseOver={() => setShowControls(true)}
                            onMouseOut={() => setShowControls(true)}
                          >
                            <source src={product?.images?.path} />
                          </video>
                        ) : (
                          <a href={product?.images?.path} className="card-img">
                            <img
                              src={product?.images?.path}
                              alt={product?.image_id}
                              className="w-100"
                            />
                          </a>
                        )}
                      </div>
                    )}
                  </Slider>
                  {product?.productImages?.length > 0 && (
                    <Slider
                      {...allProductImgsSlickSettings}
                      asNavFor={slider1}
                      ref={(slider) => setSlider2(slider)}
                    >
                      {product?.productImages?.map((img) => (
                        <React.Fragment key={img.image_id}>
                          {isVideo(img?.images?.extension) ? (
                            <>
                              <video
                                width="100%"
                                className="object-cover preview-video"
                              >
                                <source src={`${img?.images?.path}#t=0.001`} />
                              </video>
                              <span className="vdo-play-btn">
                                <FaPlayCircle />
                              </span>
                            </>
                          ) : (
                            <img
                              src={img.images?.path}
                              alt={img.image_id}
                              className="w-100"
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <h2 className="heading-product mb-2 font-freightbig-normal color-black">
                {product?.name?.split(" | ")?.[0]}
              </h2>
              <p className="fs-20 mb-2" style={{ fontStyle: "italic" }}>
                {product?.name?.split(" | ")?.[1]}
              </p>
              <span className="fs-18 bottle-ml">
                {/* {product && (
                  <span>
                    {product?.size}/ ₹ {getDiscountedPrice(product)}
                  </span>
                )}
                {product?.discount_percent && (
                  <>
                    <span className="actual-price">₹ {product?.price}</span>
                    <span className="badge badge-primary fs-15 font-weight-500 py-1 px-2 ml-2">
                      {product?.discount_percent}% OFF
                    </span>
                  </>
                )} */}
                {/* <p className="mt-3">{product?.weight}</p> */}
                {(product?.status == false || product?.status == 0) && (
                  <>
                    <div className="sold-out-label mt-3">Sold Out</div>
                    <div className="out-of-stock-label mb-5">
                      This item is currently out of stock
                    </div>
                  </>
                )}
                {/* {product?.discount_percent && <span className="badge badge-primary fs-15 font-weight-500 py-1 px-2 ml-2">{product?.discount_percent}% OFF</span>} */}
              </span>
              <div
                className="mb-5 font-garamond-semiBold"
                dangerouslySetInnerHTML={{ __html: product?.description }}
              ></div>
              {(product?.status == true || product?.status == 1) && (
                <Row className="align-items-end no-gutters mx-n2">

                  <div id="productVariants">
                    {[1, 2, 3]?.map((packNumb) => {
                      const currentPack = `pack_${packNumb}`;
                      const discount =
                        product?.[`${currentPack}_discount_percent`];
                      const price = product?.[`${currentPack}_price`];
                      const name = product?.[`${currentPack}_name`];
                      const popular = product?.[`${currentPack}_is_popular`];
                      const bestValue =
                        product?.[`${currentPack}_is_best_value`];

                      return (
                        <div
                          onClick={() => handleSelectCart(currentPack)}
                          className={`productVariant ${
                            selectPack === currentPack ? "active" : ""
                          }`}
                        >
                          <div class="productVariantTitle">{name}</div>

                          <div class="productVariant--options-priceHolder">
                            <div
                              class={`productVariantSave ${
                                !discount ? "empty" : ""
                              }`}
                            >
                              Save {discount}%
                            </div>
                            <div class="productVariant--options-price">
                              
                              <div
                                class="productVariantPrice"
                              >
                                <div
                                  class={`productVariantCompare ${!discount ? "empty" : ""
                                    }`}
                                >
                                  ₹ {price}
                                </div>
                                ₹{" "}
                                {getDiscountedPriceForPack(
                                  product,
                                  currentPack
                                )}
                              </div>
                            </div>
                          </div>
                          {popular ? (
                            <div class="productVariantBadge">Most Popular</div>
                          ) : bestValue ? (
                            <div class="productVariantBadge">Best Value</div>
                          ) : null}
                        </div>
                      );
                    })}
                    {/* <div
                    
                      onClick={() => handleSelectCart("pack_3")} className={`productVariant ${selectPack === "pack_3" ? "active" : ""
                      }`} quantity="3">
                      <div class="productVariantTitle">3 Boxes</div>

                      <div class="productVariant--options-priceHolder">
                        <div class="productVariantSave">Save 20%</div>
                        <div class="productVariant--options-price">
                          <div class="productVariantCompare" data-price-multiplier="3">₹ 200</div>
                          <div class="productVariantPrice" data-price-multiplier="0.83">₹ 150</div>
                          
                        </div>
                      </div>
                      <div class="productVariantBadge">Best Value</div>
                    </div> */}
                  </div>
                  <Col sm={12} className="mb-4 px-2">
                    {selectPack && product?.[`${selectPack}_price`] && (
                      <a
                        onClick={addToCart}
                        className="btn btn-lg fs-18 btn-secondary btn-block w-100 line-h-btn bg-hover-primary border-0"
                      >
                        Add To Bag —{" "}
                        <span>
                          ₹ {getDiscountedPriceForPack(product, selectPack)}
                        </span>
                      </a>
                    )}
                  </Col>
                </Row>
              )}
              <Accordion
                defaultActiveKey={["0"]}
                className="product-detail-accordion"
                alwaysOpen
              >
                {accordionData.map(
                  (item, i) =>
                    product?.[item.key] && (
                      <Accordion.Item eventKey={i.toString()} key={item.key}>
                        <Accordion.Header>{item.header}</Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="card-body pt-5 pb-1 px-0"
                            dangerouslySetInnerHTML={{
                              __html: product?.[item.key],
                            }}
                          ></div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                )}
              </Accordion>
            </Col>
          </Row>
        </div>
      </section>
      {supremeProducts && supremeProducts.length > 0 && (
        <section className="reveal" style={{ padding: "0px 10px" }}>
          <div className="container container-xl supreme-sec">
            <h2 className="fs-42 color-black fadeInUp animated mb-4 font-weight-bold font-freightbig-normal supreme-header">
              Frequently bought together
            </h2>
            {supremeProducts?.map((s) => (
              <Row key={s.id}>
                <Col
                  className={`mb-5 mob-order pos-relative pl-25 ${
                    s.productImages?.length > 3 && "moreImages-sup"
                  }`}
                  lg={imgBlock(s)}
                  md={imgBlock(s)}
                  sm={imgBlock(s)}
                  xs={12}
                >
                  <Row>
                    {s.productImages?.map((img, i) => (
                      <React.Fragment key={img.id}>
                        <Col
                          lg={imgSize(s)}
                          md={imgSize(s)}
                          sm={imgSize(s)}
                          xs={imgSize(s)}
                          className="pos-relative pl-0 pr-0"
                        >
                          <span className="img-title-suffix">
                            <img
                              className="supreme-img sample-img sup-img-minh"
                              src={img?.images?.path}
                              alt={img.image_id}
                            />
                            <h2
                              className="text-align-center color-black mb-0 mob-pt-10 product-heading heading-wrap pt-4 line-clamp-2"
                              dangerouslySetInnerHTML={{
                                __html: getImgTitle(s, i),
                              }}
                            ></h2>
                            <p
                              className="sub-heading-product color-black heading-wrap line-clamp-1 text-align-center"
                              dangerouslySetInnerHTML={{
                                __html: getImgSuffix(s, i),
                              }}
                            ></p>
                          </span>
                        </Col>
                        {i !== s?.productImages?.length - 1 && (
                          <Col
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            className={`pos-relative comb-add-btn ${
                              window.innerWidth < 800 && "p-0"
                            }`}
                          >
                            <FaPlus />
                          </Col>
                        )}
                      </React.Fragment>
                    ))}
                  </Row>
                </Col>
                <Col
                  className={`mb-5 mob-mb-10 pos-relative ${
                    s.productImages?.length > 3 && "moreImages-sup"
                  }`}
                  lg={prodDescBlock(s)}
                  md={prodDescBlock(s)}
                  sm={prodDescBlock(s)}
                  xs={12}
                >
                  <Row className="product-price-sec">
                    <Col lg={5} md={5} sm={5} xs={7}>
                      <h4 className="prod-name color-black">
                        {s.name?.split(" | ")?.[0]}
                      </h4>
                      <span
                        className="product-desc"
                        dangerouslySetInnerHTML={{ __html: s.description }}
                      ></span>
                    </Col>
                    <Col
                      lg={7}
                      md={7}
                      sm={7}
                      xs={5}
                      className="supreme-prod-price mob-bottom fs-12 pos-relative px-0"
                    >
                      <span className="color-black fw-600 price-font mob-bottom">
                        ₹ {getDiscountedPrice(s)}
                      </span>
                      {s.discount_percent && (
                        <>
                          <span className="actual-price fs-10">
                            ₹ {s.price}
                          </span>
                          <span className="badge badge-primary discount-badge ml-1 fs-15 font-weight-500 fs-10 py-1 px-2 ml-2">
                            {s.discount_percent}% OFF
                          </span>
                        </>
                      )}
                      <a
                        className={`btn btn-secondary fs-14 bg-hover-primary border-hover-primary mt-2 fs-12 supreme-add-btn add-cart-btn mt-15`}
                        onClick={() => addSupremeToCart(s)}
                      >
                        Add to Cart
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </div>
        </section>
      )}
      <section
        className="reveal"
        style={{ padding: "0 0 50px", marginTop: "-30px" }}
      >
        <div
          className="container-fluid mob-mb-0"
          style={{ paddingLeft: 0, paddingRight: "15px", marginBottom: "50px" }}
        >
          <div className="row mb-md-6 mb-8 mob-mb-1">
            <div className="col-md-12">
              <h2 className="fs-42 color-black fadeInUp animated text-center font-weight-bold font-freightbig-normal">
                You May Also Like
              </h2>
            </div>
          </div>
        </div>
        {otherProducts?.length > 0 && (
          <div className="container-fluid mob-prod-imgs">
            <Slider
              {...productImgsSlickSettings}
              className="products-slider imgs-slider"
            >
              {otherProducts.map((p) => (
                <div
                  className="card border-0 reveal fadeInUp animated"
                  key={p.id}
                >
                  <Link className="product-info" to={`/product-detail/${p.id}`}>
                    <div className="position-relative">
                      <img
                        src={p?.images?.path}
                        className={`img-shop ${
                          (!p.status || p.status == 0) && "unavilable-product"
                        }`}
                        alt={p?.image_id}
                      />
                      {(!p.status || p.status == 0) && (
                        <div className="out-of-stock-sec text-uppercase">
                          Out of Stock
                        </div>
                      )}
                    </div>
                    <div className="card-body px-0 pt-4 text-center height-135">
                      <p className="card-text font-weight-bold fs-16 mb-1 text-decorated">
                        <span>₹{getDiscountedPrice(p)}</span>
                        {p.discount_percent && (
                          <>
                            <span className="actual-price">₹{p.price}</span>
                            {/* <span className="discount-percent">{p.discount_percent}% off</span> */}
                            <span className="badge badge-primary fs-15 font-weight-500 py-1 px-2 ml-2">
                              {p.discount_percent}% OFF
                            </span>
                          </>
                        )}
                      </p>
                      <h2
                        className="card-title product-heading heading-wrap line-clamp-2 text-decorated"
                        title={p.name?.split(" | ")?.[0]}
                      >
                        {p.name?.split(" | ")?.[0]}
                      </h2>
                      <span
                        className="sub-heading-product heading-wrap line-clamp-1 text-decorated"
                        title={p.name?.split(" | ")?.[1]}
                      >
                        {p.name?.split(" | ")?.[1]}
                      </span>
                    </div>
                  </Link>
                  <Link
                    to={`/product-detail/${p.id}`}
                    target="_blank"
                    className="font-weight-600 brita-btn"
                  >
                    Order Now
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </section>
      {showImagePopup && (
        <Modal
          show={showImagePopup}
          onHide={closeImgPopup}
          className={`imgs-popup ${
            isVideo(currZoomImg?.images?.extension) && "video-content"
          }`}
        >
          <AiFillCaretLeft className="prev-img" onClick={goToPrevImg} />
          <button
            className="btn p-0 close-img-btn"
            type="button"
            onClick={closeImgPopup}
          >
            <AiOutlineClose />
          </button>
          <Modal.Body className="p-0">
            {isVideo(currZoomImg?.images?.extension) ? (
              <video
                className={`${!videoFullScreen && "video-cover"}`}
                height="100%"
                width="100%"
                playsInline
                controls={showControls}
                onMouseOver={() => setShowControls(true)}
                onMouseOut={() => setShowControls(true)}
              >
                <source src={currZoomImg.images?.path} />
              </video>
            ) : (
              <img
                src={currZoomImg?.images?.path}
                alt={currZoomImg?.image_id}
              />
            )}
          </Modal.Body>
          <span className="img-index">
            {currZoomImg?.index + 1} of {product?.productImages?.length}
          </span>
          <AiFillCaretRight className="next-img" onClick={goToNextImg} />
        </Modal>
      )}
    </>
  );
};

export default ProductDetail;
