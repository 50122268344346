import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { isLoggedIn, setResetPwd } from "./login-reducer";
import axios from "../axios-interceptors";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const Login = () => {

    const [userObj, setUserObj] = useState({ email: "", password: "" });
    const loggedIn = useSelector((state) => state.loggedIn.value.loggedIn);
    const isResetPwd = useSelector(state => state.loggedIn.value.resetPwd);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const setForm = (evt) => {
        setUserObj(prev => ({ ...prev, [evt.target.name]: evt.target.value }));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        axios.post(`auth/authorise`, userObj)
            .then((res) => {
                setTokens(res);
                dispatch(isLoggedIn(true));
                navigate("/home");
                // toast.success(res ? res?.message : 'Signup Successful!', {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            })
            .catch((err) => {
                toast.error(err ? err?.message : "Login Error!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            });
    }

    const onResetPwd = (e) => {
        e.preventDefault();
        axios.post(`auth/forgot/password`, { email: userObj.email })
            .then((res) => {
                dispatch(setResetPwd(false));
                toast.success(res ? res?.message : `Reset password email has been sent to ${userObj.email}`, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error(err ? err?.errors[0]?.message : "Reset password error!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            });
    }

    const setTokens = (data) => {
        localStorage.setItem("access-token", data.token);
        localStorage.setItem("refresh-token", data.refreshToken);
        if (localStorage.getItem("non-loggedIn-token")) localStorage.removeItem("non-loggedIn-token");
        if (localStorage.getItem("non-loggedIn-email")) localStorage.removeItem("non-loggedIn-email");
    }

    useEffect(() => {
        setUserObj({ email: "", password: "" });
    }, [isResetPwd])

    return (
        <>
            {/* <div id="error">
                <div className="alert alert-danger outline-2x text-center" role="alert">
                    <p className="mb-0">Email Not registered with us</p>
                </div>
            </div> */}
            {loggedIn && <div id="success">
                <div className="alert alert-success outline-2x text-center" role="alert">
                    <p className="mb-0">Login Successfully</p>
                </div>
            </div>}
            <h4 className="fs-32 text-center mb-6 color-black text-uppercase">{!isResetPwd ? "Sign In" : "Password Reset"}</h4>
            {!isResetPwd ? <form onSubmit={onSubmit}>
                {/* <input name="username" type="text" className="form-control border-0 mb-3 valid" placeholder="Username"
                    value={userObj.username} onChange={(evt) => setForm(evt)} required /> */}
                <input name="email" type="email" className="form-control border-0 mb-3 valid" placeholder="Your email"
                    value={userObj.email} onChange={(evt) => setForm(evt)} required />
                <input name="password" type="password" id="password" className="form-control border-0 valid" placeholder="Password"
                    value={userObj.password} required onChange={(evt) => setForm(evt)} />
                <div className="d-flex pull-right mt-5 mb-4 cursor-pointer">
                    <a id="nav-forgot-tab" className="text-secondary fw-600 color-black" onClick={() => dispatch(setResetPwd(true))}>
                        Forgot your password?
                    </a>
                </div>
                <button type="submit" className="btn btn-secondary btn-block bg-hover-primary border-hover-primary text-uppercase">Log In</button>
            </form> :
                <form className="reset-pwd-block" onSubmit={onResetPwd}>
                    <p className="mb-4">Please provide your email address and we will send you a password reset link within the next few minutes.</p>
                    <input name="email" type="email" className="form-control border-0 mb-3 valid" placeholder="Your email"
                        value={userObj.email} onChange={(evt) => setForm(evt)} required />
                    <button type="submit" className="btn btn-secondary btn-block bg-hover-primary border-hover-primary text-uppercase reset-btn">Reset Password</button>
                </form>
            }
        </>
    )
}

export default Login;
