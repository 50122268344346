import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../axios-interceptors";

const ResetPassword = () => {
    const [pwdObj, setPwdObj] = useState({ password: "", confirmPassword: "" });
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const navigate = useNavigate();

    const setForm = (evt) => {
        setPwdObj(prev => ({ ...prev, [evt.target.name]: evt.target.value }));
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (pwdObj.password !== pwdObj.confirmPassword) {
            toast.error("Passwords do not match!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return;
        }
        const obj = {
            token: query.get('token'),
            password: pwdObj.password
        }
        axios.post("auth/reset/password", obj).then(res => {
            toast.success(res ? res?.message : "Password has been successfully reset!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            setTimeout(() => {
                navigate("/signin");
            }, 500);
        }).catch((err) => {
            toast.error(err ? err?.errors[0]?.message : "Reset password error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
    return (
        <section className="pb-lg-13 pb-11 pt-7" style={{ marginTop: "100px", padding: "40px 0 100px 0 !important" }}>
            <div className="container">
                <h4 className="fs-32 text-center mb-7 color-black text-uppercase">Reset Password</h4>
                <Row>
                    <Col lg={{ span: 8, offset: 2 }}>
                        <form onSubmit={onSubmit} className="reset-form">
                            <input name="password" type="password" className="form-control border-0 mb-4 valid" placeholder="New Password"
                                value={pwdObj.password} onChange={(evt) => setForm(evt)} required />
                            <input name="confirmPassword" type="password" id="password" className="form-control border-0 valid" placeholder="Confirm Password"
                                value={pwdObj.confirmPassword} required onChange={(evt) => setForm(evt)} />
                            <button type="submit" className="btn btn-secondary btn-block bg-hover-primary border-hover-primary text-uppercase mt-5 mw-140">Submit</button>
                        </form>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default ResetPassword;