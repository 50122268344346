import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "../axios-interceptors";
import { isLoggedIn, profileUpdate } from "../components/login-reducer";

const Signup = () => {
    const [userObj, setUserObj] = useState({ email: "", password: "" });
    const [profile, setProfile] = useState({});
    const dispatch = useDispatch();
    const profileData = useSelector((state) => state.loggedIn.value.profileData);
    const loggedIn = useSelector((state) => state.loggedIn.value.loggedIn);
    const profileUpdated = useSelector((state) => state.loggedIn.value.profileUpdated);
    const location = useLocation();
    const navigate = useNavigate();
    const genders = ["Male", "Female", "Transgender", "Non-binary", "Prefer not to respond"];

    useEffect(() => {
        setProfile(profileData?.profile);
    }, [profileData]);

    const setForm = (evt) => {
        setUserObj(prev => ({ ...prev, [evt.target.name]: evt.target.name === "termsOfUse" ? evt.target.checked : evt.target.value }));
    }

    const setProfileForm = (evt) => {
        let val = evt.target.value;
        if (evt.target.name === "mobile") {
            val = val.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }
        setProfile(prev => ({ ...prev, [evt.target.name]: val }));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        axios.post(`auth/signup`, userObj)
            .then((res) => {
                setTokens(res);
                dispatch(isLoggedIn(true));
                getProfile(e);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err ? err?.errors[0]?.message : "Signup Error!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            });
    }

    const getProfile = (e) => {
        axios.get(`profile`).then((res) => {
            updateProfile(e, res);
        });
    }

    const updateProfile = (e, res = {}) => {
        e.preventDefault();
        axios.put(`profile/update/${profileData?.profile?.id || res.profile?.id}`, profile)
            .then((res) => {
                dispatch(profileUpdate(!profileUpdated));
                toast.success(res ? res?.message : 'Profile updated', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                setTimeout(() => {
                    if (location.pathname === '/signup') navigate("/home");
                }, 100);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err ? err?.errors[0]?.message : "Profile couldn't be updated", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            });
    }

    const setTokens = (data) => {
        localStorage.setItem("access-token", data.token);
        localStorage.setItem("refresh-token", data.refreshToken);
        if (localStorage.getItem("non-loggedIn-token")) localStorage.removeItem("non-loggedIn-token");
        if (localStorage.getItem("non-loggedIn-email")) localStorage.removeItem("non-loggedIn-email");
    }

    return (
        <section className="pb-lg-13 pb-11 pt-7 signup-section p-5" style={{ marginTop: "120px" }}>
            <Row>
                <Col lg={{ span: 6, offset: 3 }}>
                    <h4 className="fs-32 text-center mb-6 color-black text-uppercase">{loggedIn ? "Profile" : "Sign Up"}</h4>
                    <form onSubmit={loggedIn ? updateProfile : onSubmit}>
                        <Row>
                            {(location.pathname === '/signup') &&
                                <>
                                    <Col md={12}>
                                        <input name="email" type="email" className="form-control border-0 mb-3 valid" placeholder="Your email"
                                            value={userObj.email} onChange={(evt) => setForm(evt)} required />
                                    </Col>
                                    <Col md={12}>
                                        <input name="password" type="password" id="password" className="form-control mb-3 border-0 valid" placeholder="Password"
                                            value={userObj.password} onChange={(evt) => setForm(evt)} minLength="6" required />
                                    </Col>
                                </>}
                            <Col md={6}>
                                <input name="first_name" type="text" className="form-control border-0 mb-3 valid" placeholder="First Name"
                                    value={profile?.first_name || ""} onChange={(evt) => setProfileForm(evt)} required />
                            </Col>
                            <Col md={6}>
                                <input name="last_name" type="text" className="form-control border-0 mb-3 valid" placeholder="Last Name"
                                    value={profile?.last_name || ""} onChange={(evt) => setProfileForm(evt)} required />
                            </Col>
                            <Col md={12}>
                                <select name="gender" className="form-control border-0 mb-3 valid" placeholder="Select Gender"
                                    value={profile?.gender || ""} onChange={(evt) => setProfileForm(evt)} style={profile?.gender ? {} : { color: "#909090" }} required>
                                    <option value={""}>Select Gender</option>
                                    {
                                        genders.map(g => (
                                            <option key={g} value={g}>{g}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={6}>
                                <input name="city" type="text" className="form-control border-0 mb-3 valid" placeholder="City"
                                    value={profile?.city || ""} onChange={(evt) => setProfileForm(evt)} />
                            </Col>
                            <Col md={6}>
                                <input name="state" type="text" className="form-control border-0 mb-3 valid" placeholder="State"
                                    value={profile?.state || ""} onChange={(evt) => setProfileForm(evt)} />
                            </Col>
                            <Col md={6}>
                                <input name="country" type="text" className="form-control border-0 mb-3 valid" placeholder="Country"
                                    value={profile?.country || ""} onChange={(evt) => setProfileForm(evt)} />
                            </Col>
                            <Col md={6}>
                                <input name="mobile" type="text" className="form-control border-0 mb-3 valid" minLength={"10"} maxLength="10" placeholder="Mobile No"
                                    value={profile?.mobile || ""} onChange={(evt) => setProfileForm(evt)} />
                            </Col>
                        </Row>
                        <button type="submit" className="btn btn-secondary btn-block bg-hover-primary border-hover-primary mt-5 mb-5">Submit</button>
                    </form>
                </Col>
            </Row>
        </section>
    )
}

export default Signup;