import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiFillLeftCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../axios-interceptors";
import "./journal.css";

const JournalDesc = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [journal, setJournal] = useState(null);
    const journalDesc = useSelector(state => state.journal.journalDesc);

    useEffect(() => {
        if (!journalDesc) {
            axios.get(`journals/${id}`).then(res => {
                setJournal(res.data);
            }).catch(err => {
                console.log(err);
            })
        }
    }, []);

    return (
        <section className="pt-lg-14 pt-md-10 pt-7 news-section" style={{ marginTop: "100px" }}>
            <div className="container container-xl journal-desc">
                <Row>
                    <Col lg={{ span: 8, offset: 2 }}>
                        <span className='go-back-journal' onClick={() => navigate("/journal")}><AiFillLeftCircle /></span>
                        <section className="border-bottom pb-3 heading-para-garamont" dangerouslySetInnerHTML={{ __html: journalDesc || journal?.description }} >
                        </section>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default JournalDesc;