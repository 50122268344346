import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const TermsAndConditions = () => {
    const crmData = useSelector(state => state.loggedIn.value.crmData);
    const policy = () => crmData?.find(i => i?.references?.key === "t&c");

    return (
        <section className="pt-lg-14 pt-md-10 pt-7 journal-section" style={{ marginTop: "100px" }}>
            <div className="container container-xl">
                <Row>
                    <h2 className=" text-center font-weight-bold font-freightbig-normal text-uppercase mb-4" style={{ fontSize: "80px", color: "#000" }}>
                        Terms & Conditions
                    </h2>
                    <Col lg={{ span: 8, offset: 2 }}>
                        <section className="border-bottom pb-3 heading-para-garamont" dangerouslySetInnerHTML={{ __html: policy()?.description }} ></section>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default TermsAndConditions;