
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import axios from "../../axios-interceptors";
import "./samples.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { addProductToCart } from "../Product-Detail/add-to-cart-reducer";
import { showCart } from "../Product-Detail/show-cart-reducer";
import "../Product-Detail/product-detail.css";
import { BsSearch } from "react-icons/bs";
import Loader from "../../shared/loader";

const Samples = () => {
    const [sampleProducts, setSampleProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const cartvalue = useSelector((state) => state.addToCart.value);
    const profileData = useSelector((state) => state.loggedIn.value.profileData);
    const dispatch = useDispatch();
    const crmData = useSelector(state => state.loggedIn.value.crmData);
    const sampleDesc = crmData?.find(i => i?.references?.key === "sample")?.description;

    const getImgTitle = (s, i) => {
        let titles = s?.usage?.replace(/(<([^>]+)>)/ig, '')?.split("#");
        return titles?.[i]?.split(" | ")?.[0];
    }

    const getImgSuffix = (s, i) => {
        let titles = s?.usage?.replace(/(<([^>]+)>)/ig, '')?.split("#");
        return titles?.[i]?.split(" | ")?.[1];
    }

    const getDiscountedPrice = (p) => {
        let price = p.price;
        if (p.discount_percent) price = p.price - (p.price * p.discount_percent / 100);
        return Math.ceil(price);
    }

    const addSampleToCart = (s) => {
        if (checkIfAdded(s)) {
            return;
        }
        if (cartvalue.length > 0) {
            toast.error("Sample cannot be combined with other products/samples", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return;
        }
        const obj = { ...s, qty: 1, price: getDiscountedPrice(s) };
        let arr = localStorage.getItem(`cart-value${profileData?.id || ""}`) ? JSON.parse(localStorage.getItem(`cart-value${profileData?.id || ""}`)) : [];
        dispatch(addProductToCart(obj));
        arr = [...arr, obj];
        localStorage.setItem(`cart-value${profileData?.id || ""}`, JSON.stringify(arr));
        dispatch(showCart(true));
    }

    const checkIfAdded = (s) => cartvalue?.some(i => i.id === s.id);

    const imgSize = (s) => s.productImages?.length > 2 ? 3 : 5;

    useEffect(() => {
        setLoading(true);
        axios.get(`products`)
            .then((res) => {
                const samples = res.filter(i => ((i.show_product == true || i.show_product === 1) && i?.categories?.title === "Samples"));
                setSampleProducts(samples);
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    return (
        !loading ?
            <section className="pt-lg-16 pt-md-10 pt-7 top-padding">
                <h2 className="text-center font-weight-bold font-freightbig-normal text-uppercase color-black" style={{ fontSize: "70px" }}>
                    Samples
                </h2>
                <div className="samples-container">
                    {sampleDesc && <p className="sample-desc" dangerouslySetInnerHTML={{ __html: sampleDesc }}></p>}
                    <Row>
                        {sampleProducts?.length > 0 ? sampleProducts?.map(s => (
                            <React.Fragment key={s.id}>
                                <Col className={`mb-5 mob-mb-1 pos-relative ${s.productImages?.length > 3 && "moreImages-sup"}`} lg={4} md={4} sm={4} xs={7}>
                                    <Row>
                                        {
                                            s.productImages?.map((img, i) => (
                                                <React.Fragment key={img.id}>
                                                    <Col lg={imgSize(s)} md={imgSize(s)} sm={imgSize(s)} xs={imgSize(s)} className="pos-relative pl-0 pr-0">
                                                        <span className="img-title-suffix">
                                                            <img className="sample-img img-minh" src={img?.images?.path} alt={img?.image_id} />
                                                            <p className="color-black mb-0 text-align-center" dangerouslySetInnerHTML={{ __html: getImgTitle(s, i) }}></p>
                                                            <p className="text-align-center" dangerouslySetInnerHTML={{ __html: getImgSuffix(s, i) }}></p>
                                                        </span>
                                                    </Col>
                                                    {(i !== s?.productImages?.length - 1) && <Col lg={1} md={1} sm={1} xs={1} className={`pos-relative comb-add-btn ${window.innerWidth < 800 && "p-0"}`}>
                                                        <FaPlus />
                                                    </Col>}
                                                </React.Fragment>
                                            ))
                                        }
                                    </Row>
                                </Col>
                                <Col className={`mb-5 mob-mb-1 pos-relative ${s.productImages?.length > 3 && "moreImages-sup"}`} lg={2} md={2} sm={2} xs={5} key={s.id}>
                                    <Row className="product-price-sec h-80">
                                        <Col lg={12} md={12} sm={12} xs={12} className="supreme-prod-price fs-8 pos-relative mob-p-0">
                                            <h4 className="prod-name color-black">{s.name?.split(" | ")?.[0]}</h4>
                                            <span className="color-black">Rs {getDiscountedPrice(s)}</span>
                                            {s.discount_percent &&
                                                <>
                                                    <span className="actual-price fs-8 mr-2">Rs {s.price}</span>
                                                    <span className="badge badge-primary fs-15 font-weight-500 fs-7 py-1 px-2">{s.discount_percent}% OFF</span>
                                                </>
                                            }
                                            <a className={`btn btn-secondary fs-14 bg-hover-primary border-hover-primary fs-7 supreme-add-btn add-sample-cart`}
                                                onClick={() => addSampleToCart(s)} >{checkIfAdded(s) ? 'Added to Cart' : 'Add to Cart'}</a>
                                        </Col>
                                    </Row>
                                </Col>
                            </React.Fragment>
                        ))
                            :
                            <div className="no-orders">
                                <div className="no-orders-cover">
                                    <BsSearch />
                                    <span className="ml-3">No samples found.</span>
                                </div>
                            </div>
                        }
                    </Row>
                </div>
            </section> :
            <div className="text-align-center m-5 top-padding">
                <Loader />
            </div>
    )
}

export default Samples;