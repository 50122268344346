import './layout.css';
import { AiOutlineUser, AiOutlineShoppingCart } from 'react-icons/ai';
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Col, Container, Nav, Navbar, Offcanvas, Row } from 'react-bootstrap';
import Cart from './cart';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isLoggedIn, setCrmData, setProfileData } from "../components/login-reducer";
import { setCart } from "../components/Product-Detail/add-to-cart-reducer";
import axios from '../axios-interceptors';
import { showCart } from '../components/Product-Detail/show-cart-reducer';
import { resetAddressForm, setFormDirty } from '../components/add-edit-address/set-address-reducer';
import { FaUserCheck } from 'react-icons/fa';
import DiscountBanner from '../components/Home/discount-banner';
import { SocialLinks } from '../links';

const Header = () => {
    const [showCartModal, setShowCartModal] = useState(false);
    const loggedIn = useSelector((state) => state.loggedIn.value.loggedIn);
    const profileUpdated = useSelector((state) => state.loggedIn.value.profileUpdated);
    const cartvalue = useSelector((state) => state.addToCart.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openDropdown, setOpenDropdown] = useState(false);
    const profileData = useSelector((state) => state.loggedIn.value.profileData);
    const openCart = useSelector((state) => state.showCartItems.value);
    const crmData = useSelector(state => state.loggedIn.value.crmData);
    const location = useLocation();
    const [adBannerObj, setAdBannerObj] = useState({});
    const [showAdBanner, setShowAdBanner] = useState(false);

    const authPages = ["/order-history"];
    const unAuthPages = ["/signup", "/signin"];
    const currYear = new Date().getFullYear();

    const handleClickOutside = (evt) => {
        if (evt.target.name !== "dropdown") {
            setOpenDropdown(false);
        }
    }

    window.addEventListener("mousedown", handleClickOutside);

    useEffect(() => {
        if (localStorage.getItem("access-token")) {
            dispatch(isLoggedIn(true));
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem(`cart-value${profileData?.id || ""}`)) {
            dispatch(setCart(JSON.parse(localStorage.getItem(`cart-value${profileData?.id || ""}`))));
        } else if (localStorage.getItem(`cart-value`)) {
            const cart = JSON.parse(localStorage.getItem(`cart-value`));
            dispatch(setCart(cart));
            localStorage.setItem(`cart-value${profileData?.id || ""}`, JSON.stringify(cart));
            localStorage.removeItem(`cart-value`);
        }
    }, [profileData?.id]);

    useEffect(() => {
        if (location.pathname !== '/home') {
            const appHeader = document.getElementById("appHeader");
            if (appHeader) appHeader.style.top = 0;
        }
        if ((unAuthPages.includes(location.pathname) && loggedIn) || (authPages.includes(location.pathname) && !localStorage.getItem("access-token"))) {
            navigate('/home');
        }
        dispatch(resetAddressForm());
        dispatch(setFormDirty(false));
    }, [location]);

    useEffect(() => {
        axios.get(`crm`)
            .then((res) => {
                dispatch(setCrmData(res));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (crmData?.length > 0) {
            const _adBannerObj = crmData.find(i => i?.references?.key == "ad_banner");
            if (_adBannerObj) {
                setAdBannerObj(_adBannerObj);
                setShowAdBanner(true);
            }
        }
    }, [crmData]);

    const logout = () => {
        removeTokens();
        dispatch(isLoggedIn(false));
        navigate("/home");
        dispatch(setProfileData({}));
        dispatch(resetAddressForm());
        window.location.reload();
    }

    const removeTokens = () => {
        localStorage.removeItem("access-token");
        localStorage.removeItem("refresh-token");
    }

    const getCartValue = () => {
        let count = 0;
        if (localStorage.getItem(`cart-value${profileData?.id || ""}`)) {
            count = cartvalue.length;
        }
        return count;
    }

    const closeCart = () => {
        setShowCartModal(false);
        dispatch(showCart(false));
    }

    useEffect(() => {
        if (loggedIn) {
            axios.get(`profile`).then((res) => {
                dispatch(setProfileData(res));
            })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [loggedIn, profileUpdated]);

    useEffect(() => {
        if (openCart) setShowCartModal(true);
    }, [openCart]);

    const userAction = <li className="nav-item" onClick={() => navigate("/signin")}>
        <a className="nav-link pr-3 py-0 d-inline-flex header-icon">
            <AiOutlineUser />
        </a>
    </li>

    const loggedInUserAction = <li name="dropdown" className={`nav-item dropdown ${openDropdown ? 'show' : ''}`} onClick={() => setOpenDropdown(!openDropdown)}>
        <a className="nav-link pr-3 py-0 d-inline-flex header-icon">
            <FaUserCheck />
        </a>
        <div name="dropdown" className={`dropdown-menu p-0 header-d-menu ${openDropdown ? 'show' : ''}`}>
            <a name="dropdown" className="dropdown-header">Welcome {profileData?.profile?.first_name}</a>
            <a name="dropdown" className="dropdown-item" onClick={() => navigate("/profile")}>Profile</a>
            <a name="dropdown" className="dropdown-item" onClick={() => navigate("/order-history")}>Order History</a>
            <a name="dropdown" className="dropdown-item" onClick={logout}>Logout</a>

        </div>
    </li>

    const cart = <li className="nav-item" onClick={() => setShowCartModal(true)}>
        <a className="nav-link position-relative px-4 menu-cart py-0 d-inline-flex align-items-center mr-n2 header-icon mobile-view-padding">
            <AiOutlineShoppingCart />
            <span className="position-absolute number">{getCartValue()}</span>
        </a>
    </li>

    return (
        <>
            <header id="appHeader" className="main-header navbar-dark header-sticky header-sticky-smart position-absolute fixed-top">
                <div id="headerWrapper" className="sticky-area-wrap">
                    <div className="sticky-area" id="stickyArea">
                        {(showAdBanner && (location.pathname === '/home' || location.pathname==="/")) && <DiscountBanner handleClose={() => setShowAdBanner(false)} discountObj={adBannerObj} />}
                        <Navbar expand="lg" bg="dark" id="appHeader" className="bg-black">
                            <Container fluid>
                                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} className="nav-toggle-btn">
                                    <span className="fs-24 toggle-icon"></span>
                                </Navbar.Toggle>
                                <div className="mx-auto sm-none">
                                    <a className="navbar-brand d-inline-block mr-0 py-5" href="/">
                                        <img src="images/logo-white.png" alt="Britacel" className="normal-logo" />
                                        <img src="images/logo-white.png" alt="Britacel" className="sticky-logo" />
                                    </a>
                                </div>
                                <ul className="sm-none mobile-view navbar-nav flex-row justify-content-xl-end d-flex flex-wrap text-body py-0 navbar-right cursor-pointer">
                                    {loggedIn ? loggedInUserAction : userAction}
                                    {cart}
                                </ul>
                                <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand`}
                                    placement="start"
                                >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand`} className="ml-5">
                                            <a href="/" className="d-block w-179px">
                                                <img src="images/logo-white.png" alt="Britacel" />
                                            </a>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body className="d-flex align-items-center flex-nowrap mob-header-pl">
                                        <Nav className="w-50 d-flex align-items-center">
                                            <a href="#" className="nav-search d-flex search-transparent align-items-center pr-3 mr-auto">
                                                <span className="d-none d-xl-inline-block ml-2 search-transparent">.</span>
                                            </a>
                                            <ul className="navbar-nav hover-menu main-menu px-0 mx-xl-n5 posr-35">
                                                <li className="nav-item py-2 py-xl-6 sticky-py-xl-6 px-0 px-xl-5">
                                                    <Link className="nav-link p-0" to="/home">Home</Link>
                                                </li>
                                                <li className="nav-item py-2 py-xl-6 sticky-py-xl-6 px-0 px-xl-5">
                                                    <Link className="nav-link p-0" to="/shop">Shop</Link>
                                                </li>
                                                <li className="nav-item py-2 py-xl-6 sticky-py-xl-6 px-0 px-xl-5">
                                                    <Link className="nav-link p-0" to="/samples">Samples</Link>
                                                </li>
                                            </ul>
                                        </Nav>
                                        <div className="mx-auto flex-shrink-0 header-logo-sec posr-35">
                                            <div className="d-flex align-items-center w-100 justify-content-center">
                                                <a className="navbar-brand mx-4 mx-xxl-10 d-none d-xl-inline-block" href="/">
                                                    <img src="images/logo-white.png" alt="Britacel" className="normal-logo" />
                                                    <img src="images/logo-white.png" alt="Britacel" className="sticky-logo" />
                                                </a>
                                            </div>
                                        </div>
                                        <Nav className="w-50 d-flex align-items-center justify-content-between mobile-pl-2">
                                            <ul className="navbar-nav hover-menu main-menu px-0 mx-xl-n5 posr-35">
                                                <li className="nav-item py-2 py-xl-6 sticky-py-xl-6 px-0 px-xl-5">
                                                    <Link className="nav-link p-0" to="/about-us">About us</Link>
                                                </li>
                                                <li className="nav-item py-2 py-xl-6 sticky-py-xl-6 px-0 px-xl-5">
                                                    <Link className="nav-link p-0" to="/journal">Journal</Link>
                                                </li>
                                                <li className="nav-item py-2 py-xl-6 sticky-py-xl-6 px-0 px-xl-5">
                                                    <Link className="nav-link p-0" to="/contact">Contact</Link>
                                                </li>
                                            </ul>
                                            <ul className="navbar-nav desktop-view flex-row justify-content-xl-end d-flex flex-wrap text-body py-0 navbar-right cart-user cursor-pointer">
                                                {loggedIn ? loggedInUserAction : userAction}
                                                {cart}
                                            </ul>
                                        </Nav>
                                        {window.innerWidth < 992 && <Row className="mt-0 mt-lg-16 align-items-center social-mob">
                                            <Col className="col-12 col-md-6 col-lg-4 d-flex align-items-center mt-4 mt-md-7 mt-lg-0" xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                                                <ul className="list-inline fs-18 ml-3 mb-0">
                                                    {
                                                        SocialLinks.map((link) => (
                                                            <li className={link.className} key={link.name}>
                                                                <Link className="text-white-link" to={link.url} target="_blank">{link.icon}</Link>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </Col>
                                            <Col className="text-md-center" xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                                <p className="mb-0 text-white-link power-text">©. {currYear} Britacel.</p>
                                                <p className="mb-0 text-white-link power-text">All Rights Reserved.</p>
                                            </Col>
                                        </Row>}
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </Container>
                        </Navbar>
                    </div>
                </div>
            </header>
            {showCartModal && <Cart show={showCartModal} handleClose={closeCart} />}
        </>

    )
}

export default Header;