import { FaAmazon, FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa"

const Links = [
    { name: "Home", url: "/home", className: "pb-1" },
    { name: "Shop", url: "/shop", className: "py-1" },
    { name: "About us", url: "/about-us", className: "py-1" },
    { name: "Journal", url: "/journal", className: "py-1" },
    { name: "Contact", url: "contact", className: "pt-1" },
    { name: "Terms & Conditions", url: "/terms-and-conditions", className: "py-1" },
    { name: "Shipping & Return Policy", url: "/return-policy", className: "py-1" },
    { name: "Privacy Policy", url: "/privacy-policy", className: "py-1" },
]

const SocialLinks = [
    { name: "Facebook", url: "https://www.facebook.com/profile.php?id=100082123161483", className: "list-inline-item mr-4", icon: <FaFacebookF /> },
    { name: "Instagram", url: "https://www.instagram.com/britacelhealthcare/", className: "list-inline-item mr-4", icon: <FaInstagram /> },
    { name: "Youtube", url: "https://youtube.com/channel/UC5Yuw6Q2gHSIoD_GbTAKjOQ", className: "list-inline-item mr-4", icon: <FaYoutube /> },
    { name: "Amazon", url: "https://www.amazon.in/s?k=britacel+healthcare&i=hpc&crid=25MVVDJBSH4AS&sprefix=britacel%2Chpc%2C202&ref=nb_sb_ss_fb_1_8", className: "list-inline-item", icon: <FaAmazon /> },
]

const InstagramImgs = [
    { name: "Instagram 01", url: "images/Insta1.jpg" },
    { name: "Instagram 02", url: "images/Insta2.jpg" },
    { name: "Instagram 03", url: "images/Insta3.jpg" },
    { name: "Instagram 04", url: "images/Insta4.jpg" },
]

const BritacelIcons = [
    { title: "VISIT US (Virtually)", text: "www.britacelhealthcare.com", subText: "@britacelhealthcare", src: "images/Clipart1.jpg" },
    { title: "CRAFTED WITH Care & Perfection", text: "", subText: "", src: "images/Clipart2.jpg" },
    { title: "We have something to say,", text: "", subText: "CHECKOUT OUR NEWSLETTER!", src: "images/Clipart3.jpg" },
    { title: "OUR CONCEIRGE is always there to help you", text: "ask@britacelhealthcare.com", subText: "+91 40094000", src: "images/Clipart4.jpg" },
    { title: "KEEP IN TOUCH", text: "SIGN UP for discounts, new launches & giveaways!", src: "images/Clipart5.jpg" },
    { title: "PROUDLY Made In INDIA", text: "", subText: "", src: "images/Clipart6.jpg" },
]

export { Links, SocialLinks, InstagramImgs, BritacelIcons };