import Footer from "./footer";
import Header from "./header";
import { Container } from 'react-bootstrap';
import BackToTopButton from "./back-to-top-button";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";

const Layout = () => {
    const location = useLocation();

    useEffect(() => {
        if (document.getElementById("offcanvasNavbar-expand")) {
            document.getElementsByClassName("btn-close")[0].click()
        }
    }, [location]);

    const reveal = () => {
        const intersectionCallback = (entries) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                }
            }
        }
        const observer = new IntersectionObserver(intersectionCallback);
        const items = document.querySelectorAll('.reveal');
        for (const item of items) {
            observer.observe(item);
        }
    }

    var oldScrollY = window.scrollY;
    const onScroll = () => {
        var appHeader = document.getElementById("appHeader");
        var stickyHeaderWrapper = document.getElementById("headerWrapper");
        var stickyHeaderArea = document.getElementById("stickyArea");
        var backToTopBtn = document.getElementById("backToTop");

        if (window.scrollY > 85) {
            stickyHeaderArea.classList.add("header-hidden");
        }
        if (window.scrollY > 200) {
            backToTopBtn.classList.add("in");
        } else {
            backToTopBtn.classList.remove("in");
        }
        if (window.scrollY > oldScrollY) {
            appHeader.classList.add("navbar-light", "navbar-light-sticky");
            stickyHeaderWrapper.classList.add("sticky");
            stickyHeaderArea.style.position = "fixed";
            stickyHeaderArea.style.top = "0px";
        } else if (window.scrollY === 0) {
            appHeader.classList.remove("navbar-light", "navbar-light-sticky");
            stickyHeaderWrapper.classList.remove("sticky");
            stickyHeaderArea.style.removeProperty("position");
            stickyHeaderArea.style.removeProperty("top");
        } else if (window.scrollY < oldScrollY) {
            stickyHeaderArea.classList.remove("header-hidden");
        }
        oldScrollY = window.scrollY;
    };

    window.addEventListener("scroll", onScroll);
    // window.addEventListener("scroll", reveal);

    return (
        <>
            <Header />
            <Container>
                <main id="content"></main>
            </Container>
            <Footer />
            <BackToTopButton />
        </>
    )
}

export default Layout;