import { createSlice } from '@reduxjs/toolkit';

export const addressSlice = createSlice({
    name: 'address',
    initialState: {
        value: {
            first_name: "",
            email: "",
            addr_line1: "",
            addr_line2: " | ",
            city: "",
            pincode: "",
            state: "",
            latitude: 0,
            longitude: 0,
            email: "",
            mobile: "",
            type: "",
            isDefault: false,
        },
        addresses: [],
        dirty: false,
    },
    reducers: {
        setAddress: (state, action) => {
            state.value[action.payload["name"]] = action.payload.value;
        },
        addAddress: (state, action) => {
            state.addresses = action.payload;
        },
        setAddressInTotal: (state, action) => {
            state.value = action.payload;
        },
        resetAddressForm: (state) => {
            state.value = {
                first_name: "",
                email: "",
                addr_line1: "",
                addr_line2: " | ",
                city: "",
                pincode: "",
                state: "",
                latitude: 0,
                longitude: 0,
                email: "",
                mobile: "",
                type: "",
                isDefault: false,
            };
        },
        setFormDirty: (state, action) => {
            state.dirty = action.payload;
        },
    },
})

export const { setAddress, addAddress, setAddressInTotal, resetAddressForm, setFormDirty } = addressSlice.actions;

export default addressSlice.reducer;