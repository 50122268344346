import { configureStore } from '@reduxjs/toolkit';
import setAddressReducer from '../components/add-edit-address/set-address-reducer';
import journalReducer from '../components/Journal/journal-reducer';
import loginReducer from '../components/login-reducer';
import addToCartReducer from '../components/Product-Detail/add-to-cart-reducer';
import showCartReducer from '../components/Product-Detail/show-cart-reducer';

export default configureStore({
    reducer: {
        loggedIn: loginReducer,
        addToCart: addToCartReducer,
        address: setAddressReducer,
        showCartItems: showCartReducer,
        journal: journalReducer,
    },
})