import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import AddEditAddress from "./add-edit-address/add-edit-address";
import { setAddressInTotal } from "./add-edit-address/set-address-reducer";
import axios from "../axios-interceptors";
import useRazorpay from "react-razorpay";
import { toast } from 'react-toastify';
import { setCart } from "./Product-Detail/add-to-cart-reducer";
import OrderStatus from "./order-status";
import { setCrmData } from "./login-reducer";

const Checkout = () => {
    const cartvalue = useSelector((state) => state.addToCart.value);
    const dispatch = useDispatch();
    const Razorpay = useRazorpay();
    const navigate = useNavigate();
    const profileData = useSelector((state) => state.loggedIn.value.profileData);
    // const [showLoginModal, setShowLoginModal] = useState(false);
    const loggedIn = useSelector((state) => state.loggedIn.value.loggedIn);
    const formData = useSelector((state) => state.address.value);
    const addressFormDirty = useSelector((state) => state.address.dirty);
    const [couponCode, setCouponCode] = useState("");
    const [couponId, setCouponId] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [placeOrder, setPlaceOrder] = useState(false);
    const crmData = useSelector(state => state.loggedIn.value.crmData);

    useEffect(() => {
        if (loggedIn) {
            axios.get(`addresses`).then(res => {
                if (res.data && res.data.length > 0) {
                    dispatch(setAddressInTotal(res.data[0]));
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }, [loggedIn]);

    const getTotalPrice = () => {
        let price = 0;
        for (let c of cartvalue) {
            price += (c.price * c.qty);
        }
        return Math.ceil(price);
    }

    const getTotalGrossWeight = () => {
        let wt = 0;
        for (let c of cartvalue) {
            wt += ((c[`${c?.selectPack}_weight`] || 0) * c.qty);
        }
        return Math.ceil(wt);
    }

    const createOrder = () => {
        let arr = [];
        for (let c of cartvalue) {
            let obj = {
                "product_id": c.id,
                "quantity": c.qty,
                "subtotal": c.price * c.qty,
                "selectPack":c.selectPack
            }
            arr = [...arr, obj];
        }
        let obj = {
            "coupon_id": couponId,
            "discount": discount,
            "shipping_price": getShippingCharge(),
            "products": arr,
            "address_id": formData?.id,
            "total": getFinalPrice(),
            "status": "pending"
        }
        return axios.post(`orders/create`, obj);
    }

    const updateOrder = (data, order) => {
        let obj = {
            razorpay_order_id: order["Razorpayorder_id"],
            razorpay_payment_id: data.razorpay_payment_id,
            razorpay_signature: data.razorpay_signature,
        }
        return axios.put(`orders`, obj);
    }

    const handlePayment = async () => {
        setPlaceOrder(true);
        const res = await createOrder();
        const order = res.data;

        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            // amount: order.total * 100,
            currency: "INR",
            name: "BRITACEL HEALTH CARE PRIVATE LIMITED",
            description: "Transaction",
            image: "https://cdn.razorpay.com/logos/Hj5yzY2cNPPlly_medium.png",
            order_id: order["Razorpayorder_id"],
            handler: async (res) => {
                const update = await updateOrder(res, order);
                if (update.status === "success") {
                    localStorage.removeItem(`cart-value${profileData?.id || ""}`);
                    if (localStorage.getItem("non-loggedIn-email")) localStorage.removeItem("non-loggedIn-email");
                    toast.success('Order placed', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    setTimeout(() => {
                        dispatch(setCart([]));
                        navigate(`/order-details/${order?.id}/${order?.oid}?from=checkout`);
                    }, 300);
                } else if (update.status === "error") {
                    toast.error(update.message, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            },
            "modal": {
                "ondismiss": () => {
                    setPlaceOrder(false);
                }
            },
            prefill: {
                // name: "Prassami Naishadh Desai",
                email: "prassami.desai@gmail.com",
                contact: "9324941323",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    };

    const appleCoupon = () => {
        if (couponCode) {
            axios.get(`coupons/${couponCode}`).then(res => {
                if (res.status == 'success') {
                    setCouponCode("");
                    setCouponId(res?.data?.id);
                    let totalPrice = getTotalPrice();
                    if (res?.data?.type == "currency") {
                        setDiscount(res.data.discount_value || 0);
                    } else if (res?.data?.type == "percent") {
                        let _discount = 0;
                        _discount = Math.ceil(totalPrice * res.data.discount_value / 100);
                        setDiscount(_discount);
                    }
                }
            }).catch(err => {
                console.log(err);
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        }
    }

    const checkSamplesInCart = () => cartvalue?.find(i => i?.categories?.title === "Samples");

    const getShippingCharge = () => {
        let amount = 0;
        if (checkSamplesInCart()) {
            let sampleShippingCost = crmData?.find(i => i?.references?.key === "sample_shipping_charges")?.description;
            sampleShippingCost = sampleShippingCost?.split("</p>")[0]?.replace(/(<([^>]+)>)/ig, '');
            amount = parseFloat(sampleShippingCost);
        } else {
            amount = getTotalPrice() - discount;
            amount = amount < 999 ? shippingCost() : 0;
        }
        return amount;
    }

    const shippingCost = () => {
        let amount = 0;
        if (getTotalGrossWeight() <= 1000) amount = 50;
        else if (getTotalGrossWeight() > 1000 && getTotalGrossWeight() <= 2000) amount = 60;
        else if (getTotalGrossWeight() > 2000 && getTotalGrossWeight() <= 3000) amount = 70;
        else if (getTotalGrossWeight() > 3000) amount = 80;
        return amount;
    }

    const getFinalPrice = () => {
        const amount = getTotalPrice() - discount;
        let amt = amount < 499 ? amount + getShippingCharge() : amount;
        return Math.ceil(amt);
    }

    useEffect(() => {
        if (checkSamplesInCart()) {
            axios.get(`crm`)
                .then((res) => {
                    dispatch(setCrmData(res));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);


    return (
        <>
            {/* <section className="py-2 bg-gray-2" style={{ marginTop: "150px" }} data-animated-id="1">
                <div className="container container-xl">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-site py-0 d-flex justify-content-center">
                            <li className="breadcrumb-item">
                                <Link className="text-decoration-none text-body" to="/home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active pl-0 d-flex align-items-center" aria-current="page">Check Out
                            </li>
                        </ol>
                    </nav>
                </div>
            </section> */}
            <section className="pb-lg-13 pb-11 pt-7" style={{ marginTop: "60px" }}>
                <div className="container">
                    <OrderStatus currStep={!placeOrder ? "Shipping" : "Payment"} />
                    <Row>
                        {cartvalue?.length > 0 && <Col lg={4} className="pb-lg-0 pb-11 order-lg-last">
                            <div className="card border-0 card-box-shadow">
                                <div className="card-header px-0 mx-6 bg-transparent py-5">
                                    <h4 className="fs-24 mb-4 color-black fw-600">Order Summary</h4>
                                    <div className="scrollable-price-area">
                                        {
                                            cartvalue.map(i => (
                                                <div className="media w-100 mb-4" key={i.id}>
                                                    <div className="w-60px mr-3">
                                                        <img src={i.images.path} alt={i.name?.split(" | ")?.[0]} />
                                                    </div>
                                                    <div className="media-body d-flex">
                                                        <div className="cart-price pr-2">
                                                            {/* <Link to={`/product-detail/${i.id}`} className="text-secondary pr-2 color-black">{i.name}</Link> */}
                                                            <a className="text-secondary pr-2 color-black">{i.name?.split(" | ")?.[0]} {i?.selectPack ? `${(i?.[`${i?.selectPack}_name`])}` : ""}</a>
                                                            <p className="fs-14 text-secondary mb-0 mt-1 color-black">
                                                                Qty:<span className="text-body"> {i.qty}</span>
                                                            </p>
                                                        </div>
                                                        <div className="ml-auto price-section">
                                                            <p className="fs-14 text-secondary mr-3 mb-0 font-weight-bold color-black">₹ {(i.price * i.qty)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="card-body px-6 pt-5">
                                    <div className="d-flex align-items-center mb-2">
                                        <span>Sub Total Amount:</span>
                                        <span className="d-block ml-auto text-secondary font-weight-bold color-black">₹{getTotalPrice()}</span>
                                    </div>
                                    {!checkSamplesInCart() && <div className="align-items-center">
                                        <span>Apply Coupon:</span>
                                        <div className="couponsForm-base-textInputContainer mt-2">
                                            <input type="text" id="coupon-input-field" className="form-control couponsForm-base-textInput" placeholder="Enter coupon code"
                                                value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                                            <div className="couponsForm-base-applyButton" onClick={appleCoupon}>APPLY</div>
                                        </div>
                                    </div>}
                                    {!!discount && <div className="d-flex align-items-center mb-2 mt-5">
                                        <span>Coupon Discount:</span>
                                        <span className="d-block ml-auto text-secondary font-weight-bold color-black">-₹{discount}</span>
                                    </div>}
                                    <div className="align-items-center mb-2 mt-5">
                                        {(((getTotalPrice() - discount) < 999) && !checkSamplesInCart()) &&
                                            <p className="fw-600 color-black">Add more ₹{(999 - getTotalPrice() - discount)} to get free shipping</p>
                                        }
                                        <div className="d-flex">
                                            <span>Shipping Charges:</span>
                                            <span className="d-block ml-auto text-secondary font-weight-bold color-black">₹ {getShippingCharge()}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-transparent px-0 pb-1 mx-6">
                                    <div className="d-flex align-items-center font-weight-bold mb-3">
                                        <span className="text-secondary color-black">Total Amount:</span>
                                        <span className="d-block ml-auto text-secondary fs-24 font-weight-bold color-black">₹{getFinalPrice()}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>}
                        <Col lg={8} className="pr-xl-15 order-lg-first form-control-01">
                            <div className="card border-0 px-4 py-5 card-box-shadow">
                                <h4 className="pull-left color-black mb-4 fw-600">Shipping Infomation</h4>
                                <AddEditAddress />
                            </div>
                            <div className="place-order-sec">
                                <Row>
                                    <Col lg={9} xl={9} md={8} sm={7} xs={6}>
                                        {formData?.id && <label className="radio-inline add-redio width-100">
                                            <span className="color-black radio-btn-pos mr-3">{formData.first_name}</span>
                                            <p className="mb-0">{`${formData.addr_line1}, ${formData.city}, ${formData.state}, ${formData.pincode}`}</p>
                                            <span className="color-black">Phone number </span>&nbsp;{formData?.mobile}
                                        </label>}
                                    </Col>
                                    <Col lg={3} xl={3} md={4} sm={5} xs={6} className="v-middle">
                                        <button type="button" className="btn btn-secondary bg-hover-primary border-hover-primary px-4 place-order-btn" name="placeorder"
                                            disabled={!formData?.id || addressFormDirty || cartvalue?.length === 0} onClick={handlePayment}>
                                            Place Order
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            {/* {showLoginModal && <LoginModal show={showLoginModal} handleClose={() => setShowLoginModal(false)} />} */}
        </>
    )
}

export default Checkout;