import { Row, Col } from "react-bootstrap";
import "./journal.css";
import { useEffect, useRef, useState } from "react";
import axios from "../../axios-interceptors";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAllJournals, setJournalDesc } from "./journal-reducer";

const Journal = () => {
    const dispatch = useDispatch();

    const journals = useSelector(state => state.journal.journals);
    const checkVideoJournal = () => journals?.find(j => j.source_type === "video");
    const checkOtherJournals = () => journals?.filter(j => j.source_type !== "video");
    const vidRef = useRef();
    const [videoFullScreen, setVideoFullScreen] = useState(false);
    const [showControls, setShowControls] = useState(false);

    window.addEventListener('resize', () => {
        if (window.innerHeight == window.screen.height) {
            setVideoFullScreen(true);
        } else {
            setVideoFullScreen(false);
        }
    });

    useEffect(() => {
        if (journals.length === 0) {
            axios.get("journals").then(res => {
                dispatch(setAllJournals(res));
            }).catch(err => {
                console.log(err);
            })
        }
    }, []);

    let playState = null;

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (!entry.isIntersecting) {
                vidRef.current?.pause();
                playState = false;
            } else {
                vidRef.current?.play();
                playState = true;
            }
        });
    }, {});

    const onVisibilityChange = () => {
        if (document.hidden || !playState) {
            vidRef.current?.pause();
        } else {
            vidRef.current?.play();
        }
    };

    document.addEventListener("visibilitychange", onVisibilityChange);

    useEffect(() => {
        if (vidRef.current && checkVideoJournal()) {
            observer.observe(vidRef.current);
        }
    }, [checkVideoJournal]);

    return (
        <>
            {/* <section className="py-2 bg-gray-2" style={{ marginTop: "150px" }} data-animated-id="1">
                <div className="container container-xl">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-site py-0 d-flex justify-content-center">
                            <li className="breadcrumb-item">
                                <Link className="text-decoration-none text-body" to="/home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active pl-0 d-flex align-items-center" aria-current="page">Journal
                            </li>
                        </ol>
                    </nav>
                </div>
            </section> */}
            <section className="pt-lg-14 pt-md-10 pt-7 news-section" style={{ marginTop: "60px" }}>
                <div className="container container-xl">
                    <Row>
                        <Col lg={{ span: 8, offset: 2 }}>
                            <img className="journal-icon" src="images/journal-icon.png" alt="journal" />
                            <h2 className=" text-center mb-0 font-weight-bold font-freightbig-normal" style={{ fontSize: "80px", color: "#000" }}>
                                NEWSLETTER
                            </h2>
                            <p className="creative-para">A CREATIVE JOURNAL BY BRITACEL</p>
                        </Col>
                        {checkVideoJournal() && <Col className="mb-6 mb-lg-0" lg={7}>
                            <div className="hover-shine">
                                {/* <iframe width="100%" height="510" src="https://www.youtube-nocookie.com/embed/hSoUjfcB78A?autoplay=1&mute=1&loop=1&playlist=hSoUjfcB78A&rel=0" frameBorder="0" allowFullScreen></iframe> */}
                                <video className={`${!videoFullScreen && "video-cover"}`} ref={vidRef} height="510" width="100%" loop muted autoPlay playsInline controls={showControls}
                                    onMouseOver={() => setShowControls(true)} onMouseOut={() => setShowControls(true)}>
                                    <source src={checkVideoJournal()?.images?.path} />
                                </video>
                            </div>
                            <div className="card-body p-0 text-center mxw-630 mx-auto cursor-pointer" style={{ marginTop: "20px" }} onClick={() => dispatch(setJournalDesc(checkVideoJournal()?.description))}>
                                <h3 className="card-title mb-2 lh-129">
                                    <Link to={`/journal/${checkVideoJournal()?.id}`} className="font-freightbig-normal text-decorated fs-28">
                                        {checkVideoJournal()?.title}
                                    </Link>
                                </h3>
                            </div>
                        </Col>}
                        <Col className="mb-6 mb-lg-0" lg={5}>
                            <div className="card-body px-0 pb-0" style={{ paddingTop: "0" }}>
                                <ul className="list-unstyled mb-0">
                                    {
                                        checkOtherJournals()?.slice(0, 3)?.map(j => (
                                            <li className="blog-bottom-margin" key={j.id}>
                                                <div className="media align-items-center cursor-pointer" onClick={() => dispatch(setJournalDesc(j.description))}>
                                                    <Link to={`/journal/${j.id}`} className="d-block mr-4 left-blog-img">
                                                        <img src={j?.images?.path} alt={j.title} />
                                                    </Link>
                                                    <div className="media-body">
                                                        <Link to={`/journal/${j.id}`} className="font-weight-600 font-freightbig-normal fs-28 text-decorated">{j.title}</Link>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </Col>
                        {
                            checkOtherJournals()?.slice(3, checkOtherJournals()?.length)?.map(j => (
                                <Col className="mb-6 mb-lg-0 cursor-pointer" lg={3} key={j.id} onClick={() => dispatch(setJournalDesc(j.description))}>
                                    <Link className="hover-shine fadeInUp animated" to={`/journal/${j.id}`}>
                                        <img className="w-100 three-blog-img" src={j?.images?.path} alt={j.title} />
                                    </Link>
                                    <div className="card-body p-0 text-center mxw-630 mx-auto" style={{ marginTop: "20px" }}>
                                        <div className="card-title mb-2 fs-28 lh-129">
                                            <Link to={`/journal/${j.id}`} className="font-freightbig-normal text-decorated">{j.title}</Link>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </section>
        </>
    )
}
export default Journal;