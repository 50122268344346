import { createSlice } from '@reduxjs/toolkit';

export const journalSlice = createSlice({
    name: 'journal',
    initialState: {
        journals: [],
        journalDesc: ""
    },
    reducers: {
        setAllJournals: (state, action) => {
            state.journals = action.payload;
        },
        setJournalDesc: (state, action) => {
            state.journalDesc = action.payload;
        }
    },
})

export const { setAllJournals, setJournalDesc } = journalSlice.actions;

export default journalSlice.reducer;