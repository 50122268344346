import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../axios-interceptors";
import { SortAndMap } from "../../shared/shuffle-array";

const Shop = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`products`)
            .then((res) => {
                setProducts(SortAndMap(res));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <section className="pt-lg-16 pt-md-10 pt-7 top-padding">
            <div className="container container-xl">
                <Row style={{ justifyContent: "center" }}>
                    {
                        products.map((p, i) => (
                            ((p.show_product == true || p.show_product === 1) && p?.categories?.title === "All Products") &&
                            <Col lg={3} xs={6} className={`mb-6 mb-lg-0 cursor-pointer mob-mb-shop-prod ${i % 2 === 0 ? 'mob-pr' : 'mob-pl'} ${(!p.status || p.status == 0) && 'unavilable-product'}`} key={p.id} onClick={() => navigate(`/product-detail/${p.id}`)}>
                                <div className="hover-shine shop-img fadeInUp animated">
                                    <div className="product-title font-weight-bold font-freightbig-normal text-uppercase">
                                        <Row><span>{p.name?.split(" | ")?.[0]}</span></Row>
                                    </div>
                                    <img className="w-100" src={p?.images?.path} alt={p?.image_id} />
                                    <Row><span className="text-uppercase subtitle">{p.name?.split(" | ")?.[1]}</span></Row>
                                    {(!p.status || p.status == 0) && <div className="out-of-stock-sec text-uppercase">Out of Stock</div>}
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </div>
        </section>
    )
}

export default Shop;