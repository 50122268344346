import { Routes, Route } from "react-router-dom";
import AboutUs from "./components/about-us";
import Checkout from "./components/checkout";
import ContactUs from "./components/Contact-Us/contact-us";
import Home from "./components/Home/home";
import Journal from "./components/Journal/journal";
import JournalDesc from "./components/Journal/journal-desc";
import OrderDetails from "./components/order-details/order-details";
import OrderHistory from "./components/order-history/order-history";
import PreCheckout from "./components/pre-checkout";
import PrivacyPolicy from "./components/privacy-policy";
import ProductDetail from "./components/Product-Detail/product-detail";
import ResetPassword from "./components/reset-password/reset-password";
import ReturnPolicy from "./components/return-policy";
import Samples from "./components/samples/samples";
import Shop from "./components/Shop/shop";
import TermsAndConditions from "./components/terms-and-conditions";
import Signin from "./layout/signin";
import Signup from "./layout/signup";

const RoutingModule = () => {
    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/profile" element={<Signup />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/samples" element={<Samples />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/journal" element={<Journal />} />
            <Route path="/journal/:id" element={<JournalDesc />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/return-policy" element={<ReturnPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/product-detail/:id" element={<ProductDetail />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/pre-checkout" element={<PreCheckout />} />
            <Route path="/order-history" element={<OrderHistory />} />
            <Route path="/order-details/:id/:oid" element={<OrderDetails />} />
        </Routes>
    )
}

export default RoutingModule;