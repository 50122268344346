
import { createSlice } from '@reduxjs/toolkit';

export const showCartSlice = createSlice({
    name: 'showCartItems',
    initialState: {
        value: false,
    },
    reducers: {
        showCart: (state, action) => {
            state.value = action.payload;
        },
    },
})

export const { showCart } = showCartSlice.actions;

export default showCartSlice.reducer;